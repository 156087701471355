import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import SkeletonLoadScreen from "../../components/ImageLoader/SkeletonLoadScreen";
//screens
import BIAppComponent from "../BIAppComponent/BIAppComponent";
import Template3 from "./Pages/Template3";
import Template2 from "./Pages/Template2";
import Template4 from "./Pages/Template4";
import Template6 from "../NewRegister/Templates/Template6";
import Template7 from "../NewRegister/Templates/Template7";
import Template8 from "../NewRegister/Templates/Template8";
import Template9 from "../NewRegister/Templates/Template9";

import {
  getAppDashboardDetails,
  getLayoutApps,
  updateAppDashboardState,
  updated_total_views,
} from "../../redux/actions";
// layouts
import TemplateLayout from "./Components/TemplateLayout";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import FilterReports from "./Components/FilterReports";
import { config } from "../../config/config";

const getRequiredTemplate = (type, handleNavigation, report, reportData) => {
  switch (type) {
    case 2:
      return (
        <Template2
          activeReport={report}
          handleNavigation={handleNavigation}
          data={reportData}
        />
      );
    case 3:
      return (
        <Template3
          handleNavigation={handleNavigation}
          activeReport={report}
          data={reportData}
        />
      );
    case 4:
      return (
        <Template4
          handleNavigation={handleNavigation}
          activeReport={report}
          data={reportData}
        />
      );
    default:
      return (
        <Template2
          activeReport={report}
          handleNavigation={handleNavigation}
          data={reportData}
        />
      );
  }
};

const getNewTemplateComponent = (type, appData) => {
  switch (type) {
    case 6:
      return <Template6 appData={appData.app_details.defined_categories} />;
    case 7:
      return <Template7 appData={appData.app_details.defined_categories} />;
    case 8:
      return <Template8 appData={appData.app_details.defined_categories} />;
    case 9:
      return <Template9 appData={appData.app_details.defined_categories} />;
    default:
      return null;
  }
};

const Template = ({ appDashboardState, allApps, match, user, layoutApps }) => {
  const { appInfo } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    appData,
    searchVal = "",
    search_columns = [],
    appDataStatus = "pending",
  } = appDashboardState;
  const [reqAppDetails, setReqAppDetails] = useState([]);
  const [breadCrumbsData, setBreadCrumbsData] = useState([]);
  const [report, setReport] = useState({});
  const [appAccess, setAppAccess] = useState(false);

  const resetStates = () => {
    setReqAppDetails([]);
    setBreadCrumbsData([]);
    setReport({});
    setAppAccess(false);
  };

  const handleNavigation = (data) => {
    if (
      (data.url &&
        (data.url.includes("embed") ||
          data.url.includes("embedview") ||
          data.url.includes("reportEmbed"))) ||
      data.sub_category?.length
    ) {
      history.push(`${window.location.pathname}&${data.sub_category_id}`);
    } else if (data.url) {
      dispatch(
        updated_total_views({
          app_id: appData.app_id,
          sub_category_id: data.sub_category_id,
        })
      );
      window.open(data.url, "_blank");
    }
  };
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "current_page",
      current_page: "app_dashboard",
    });
  }, []);

  useEffect(() => {
    if (layoutApps?.status === "pending") {
      dispatch(
        getLayoutApps({
          user_id: user.preferred_username,
        })
      );
    }
    return () => {
      dispatch(
        updateAppDashboardState({
          search_columns: [],
          filteredReportsList: [],
          loadingFilter: "pending",
          searchVal: "",
          filterOptions: config.initailOptions,
          filterList: config.initailFilters,
          appData: {},
          appDataStatus: "pending",
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user.preferred_username]);

  useEffect(() => {
    if (appInfo && appDataStatus === "pending") {
      const reqAppId = appInfo.split("&")[0];
      const app_id = reqAppId.split("_")[0];
      const payload = {
        type: "app_id",
        app_id,
        approval_status: "published",
      };
      dispatch(getAppDashboardDetails(payload));
    }

    return () => {
      resetStates();
      // TODO: later
      // dispatch(cleanAppDetails());
      // create a function to clean the states
    };
  }, [appDataStatus, appInfo, dispatch]);

  useEffect(() => {
    if (appInfo && appDataStatus === "success" && Object.keys(appData).length) {
      if (appData.access_details.access_type === "private") {
        const indx = appData.access_details.users_list.findIndex(
          (app_user) =>
            app_user.user_id.toLowerCase() ===
            user.preferred_username.toLowerCase()
        );
        if (indx >= 0 || user.role === "super_admin") {
          setAppAccess(true);
        } else {
          setAppAccess(false);
        }
      } else if (appData.access_details.access_type === "public") {
        setAppAccess(true);
      }
    } else if (
      (appDataStatus === "success" && !Object.keys(appData).length) ||
      appDataStatus === "error"
    ) {
      history.push("/explore");
    }
  }, [
    appData,
    appDataStatus,
    appInfo,
    history,
    user.preferred_username,
    user.role,
  ]);

  useEffect(() => {
    if (!appInfo || !appData?.app_details?.defined_categories) return;
    const reqIds = appInfo.split("&");
    // setting it for default page or define_categories page coz it don't hv cat_id.
    let breadData = [
      {
        link: `/${reqIds[0]}`,
        name: appData.app_meta.app_name,
      },
    ];
    if (reqIds.length > 1) {
      let reqData = appData.app_details;
      reqIds.forEach((item, i) => {
        if (!reqData || i === 0) return;
        reqData = (reqData.sub_category || reqData.defined_categories)?.find(
          (subId) => subId.sub_category_id === item
        );
        // skipping breadcrum for parentReport coz we don't want this in our breadcrumbs
        if (reqData && reqData.sub_category_type !== "parentReport") {
          breadData.push({
            link: `/${reqIds.slice(0, i + 1).join("&")}`,
            name: reqData.sub_category_name?.startsWith('{"blocks"')
              ? JSON.parse(reqData.sub_category_name).blocks[0]?.text
              : reqData.sub_category_name,
          });
        }
      });
      setBreadCrumbsData(breadData);
      if (reqData) {
        setReqAppDetails(reqData.sub_category);
        setReport(reqData);
      } else history.push("/explore");
    } else {
      //handling the default page or define_categories coz it's object don't hv sub_cat id.
      setReqAppDetails(appData.app_details.defined_categories);
      setBreadCrumbsData(breadData);
      setReport(appData.app_details);
    }
  }, [appData, appInfo, history]);

  useEffect(() => {
    if (appData?.app_id) {
      dispatch(
        updated_total_views({
          app_id: appData.app_id,
        })
      );
    }
  }, [appData.app_id, dispatch]);

  return (
    <>
      {appDataStatus === "loading" && (
        <LayoutTopSideBottom>
          <SkeletonLoadScreen showBiSkel />
        </LayoutTopSideBottom>
      )}
      {appDataStatus === "success" && appData.app_details && (
        <>
          {!appData.app_details.chosen_template ||
          parseInt(appData.app_details.chosen_template) === 1 ? (
            <BIAppComponent match={match} appAccess={appAccess} />
          ) : parseInt(appData.app_details.chosen_template) >= 6 ? (
            <>
              {getNewTemplateComponent(
                parseInt(appData.app_details.chosen_template),
                appData
              )}
            </>
          ) : (
            <TemplateLayout
              setBreadCrumbsData={setBreadCrumbsData}
              breadCrumbsData={breadCrumbsData}
              reqAppDetails={reqAppDetails}
              report={report}
              appAccess={appAccess}
            >
              {(search_columns.length > 0 || searchVal) &&
              breadCrumbsData.length === 1 ? (
                <FilterReports />
              ) : (
                getRequiredTemplate(
                  parseInt(appData.app_details.chosen_template),
                  handleNavigation,
                  report,
                  reqAppDetails
                )
              )}
            </TemplateLayout>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
  appDashboardState: state.appDashboard.appDashboardState,
  allApps: state.AppsData.allApps,
  layoutApps: state.AppsData.layoutApps,
  user: state.user,
});

export default connect(mapStateToProps)(Template);
