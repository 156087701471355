/*-------------------------Actual APIs-------------------------*/
const backendApi = "/api";
const signInUrl = `/auth/sign_in`;
const signOutUrl = `/auth/sign_out`;
const authUrl2 = "userinfoV2";
const authUrl = `${backendApi}/${authUrl2}`;
const veritasAppUrl = "https://veritas.rbone.net/";
const aideAppUrl = "https://gcp-aide-prd.rbone.net/auth/sign_in";
const mroiAppUrl = "https://gcp-aide-prd.rbone.net/home/recent/MROI";
const mtaAppUrl = "https://gcp-aide-uat.rbone.net/home/recent/MTA";
const aeAppUrl = "https://gcp-aide-prd.rbone.net/home/recent/AUDIENCE%20ENGINE";
const dataCatalogNewUrl = "https://rb.alationcatalog.com/";
const dataCatalogSourcesUrl = `${backendApi}/dataCatalogCollections`;
const dataCatalogDownloadUrl = `${backendApi}/dataCatalogDownload`;
const dataCatalogArchetypeUrl = `${backendApi}/dataCatalogArchetype`;
const dataCatalogTableUrl = `${backendApi}/dataCatalogSources`;
const dataAssetsTableUrl = `${backendApi}/dataAssetsSources`;
const dataCatalogCountryUrl = `${backendApi}/dataCatalogCountries`;
const dataCatalogBrandUrl = `${backendApi}/dataCatalogBrands`;
const covidDashboardUrl = `${backendApi}/covidDashboard`;
const covidCommentsUrl = `${backendApi}/comments`;
const commentOperationUrl = `${backendApi}/comment`;
const reportDetailsUrl = `${backendApi}/reportDetails`;
const tweetBrandsUrl = `${backendApi}/twitterBrands`;
const tweetsUrl = `${backendApi}/tweets`;
const dataAssetsIframeUrl = `${backendApi}/integrateSigview`;
const ratifyAppUrl = "https://gcp-ratify-prd.rbone.net/auth/sign_in";
const celonisAppUrl =
    "https://rb.okta.com/app/celonis/exkmk92eu971eHQ2u0x7/sso/saml";
const templateUrl = `/registertemplate`;
const appDetailsUrl = `/apps`;
const viewsAndCommentUrl = "/commentsviews";
const accessHistoryUrl = "/accesshistory";
const addCommentsUrl = "/addcomments";
const addLikesUrl = "/likes";
const uploadImageUrl = "/imageuploadv2";
const fav_app_url = `${backendApi}/apps?type=favourites`;
const userListUrl = `/allusers`;
const publishAppUrl = "/userpublish";
const emailForStatus = "adminsmail";
const emailForRequestApp = "requestmail";
const accessUpdateUrl = "accessupdate";
const sendMailToUsersAccessUrl = "mailtouser";
const backend_unique_url = "uniquebackendrepo";
const fontend_unique_url = "uniquefrontendrepo";
const sendMailToOpsUrl = "mailtoops";
const get_Layout_Url = "getlayout";
const get_recommended_URL = "recommendedapps";
const add_layout_Url = `${backendApi}/addlayout`;
const uniqueAppUrl = "uniqueappurl";
const updateTagsDatasetsUrl = "metadata";
const apphandleUniqueUrl = "uniqueapphandle";
const usageReportUsersUrl = "appuserlist";
const userAccessListUrl = "requestaccessdata";
const signupTemplatesUrl = "signuptemplate";
const jobRoleUrl = "addjobrole";
const functionsUrl = "addfunctions";
const updateLayoutUrl = "addlayout";
const draft_app_url = "draftapp";
const sync_access_url = "mlaccesssync";
const help_center_temp_url = "tickettemplate";
const feedbackUrl = "feedback";
const allAppListUrl = "allapps";
const feedbackmailUrl = "feedbackmail";
const featuremailUrl = "featuremail";
const incidentmailUrl = "incidentmail";
const saveTicketMetaUrl = "jiraticket";
const savePositionMetaUrl = "positionmeta";
const createAzureGroupUrl = "azuregroup";
const updateAzureGroupMembersUrl = "groupmember";
const powerBiWorkspaceUrl = "powerbi_workspace";
const powerBiReportsUrl = "powerbi_reports";
const userFiltersUrl = "filters";
const usageMetricsURL = "usagemetrics";  //shashank's change

const messages = {
    authenticatingUserMessage: "Authenticating user",
    confirmDelete: "Are you sure you want to delete?",
    confimation: "Are you sure you want to continue",
    noDatasources: "No datasets available",
    noData: "No data available",
    productionBannerMessage:
        "We will soon be decommissioning all user data from the UAT environment. Please access the Production environment by visiting",
    requestSubmitMessage:
        "Thank you for your request! Our team will reach out to you shortly for further information. Have a great day ahead.",
    tryAgainMsg: "Some error occured. Please try again!",
    disclaimerMessage:
        "Disclaimer: Start, End and Last Updated Date are based on country only. Brand granularity is not considered.",
    loginPrimaryText: "One stop for data, anytime, anywhere.",
    loginSecondaryText:
        "Get a glimpse of all data available in Reckitt across functions, view reports, request for additional data, run AI models and much more! Find out by signing in!",
    RegisterationConfirmationText:
        "Once your request is submitted, you cannot update configurations.",
    dataReckittQuote:
        "Data@Reckitt: One stop for all your data, anytime, anywhere.",
    FaqQuote:
        "Have questions? We are here to help."
};

const hardCoded = {
    appUrl: ".rbone.net",
    homeLocation: "/explore",
    mappingStructure: {
        country: "name",
        brand: "id",
        archetype: "id",
    },
    covidDataAppId: "covid-data",
    hygieneECAppId: "hygiene-ec",
    healthECAppId: "health-ec",
    nioReportsId: "nio-reports",
    GMHealthId: "gm-health",
    RGMcockpitId: "rgm-cockpit",
    careId: "care",
    eReckittId: "e-reckitt",
    SE_MedicalId: "se-medical",
    rndLTAppId: "rnd-lt",
    glotAppId: "golt",
    dataQualityAppId: "data-quality",
    embedded_analytics: "Embedded Analytics",
    your_discription: "Your description here.",
    untitled_card: "Untitled card - ",
    your_header: "Your Header Name",
    your_subheader: "Your Sub header - ",
    horizontal_report: "Horizontal Report ",
    fourteen_days_val: 1209600000,
};
const specialFields = [
    "tags",
    "associated_datasets",
    "geography",
    "line_of_business",
    "function",
];
const initailFilters = {
    tags: [],
    associated_datasets: [],
    geography: [],
    function: [],
    line_of_business: [],
    app_category: "",
    app_type: "",
};
const initailOptions = {
    tags: [],
    associated_datasets: [],
    geography: [],
    function: [],
    line_of_business: [],
    app_category: "",
    app_type: "",
};
const colors = [
    "#4D4D4D",
    "#FFFFFF",
    "#F44E3B",
    "#FE9200",
    "#FCDC00",
    "#DBDF00",
    "#A4DD00",
    "#68CCCA",
    "#73D8FF",
    "#AEA1FF",
    "#FDA1FF",
    "#282828",
    "#333333",
    "#cccccc",
    "#D33115",
    "#E27300",
    "#FCC400",
    "#B0BC00",
    "#68BC00",
    "#16A5A5",
    "#009CE0",
    "#7B64FF",
    "#FA28FF",
    "#FC007F",
    "#000000",
    "#B3B3B3",
    "#9F0500",
    "#C45100",
    "#FB9E00",
    "#808900",
    "#194D33",
    "#0C797D",
    "#0062B1",
    "#653294",
    "#AB149E",
    "#FF4C4A",
];
const api = {
    authUrl,
    signInUrl,
    signOutUrl,
    veritasAppUrl,
    aideAppUrl,
    dataCatalogNewUrl,
    dataCatalogSourcesUrl,
    dataCatalogDownloadUrl,
    dataCatalogArchetypeUrl,
    dataCatalogTableUrl,
    dataAssetsTableUrl,
    dataCatalogCountryUrl,
    dataCatalogBrandUrl,
    dataAssetsIframeUrl,
    covidDashboardUrl,
    covidCommentsUrl,
    commentOperationUrl,
    reportDetailsUrl,
    tweetBrandsUrl,
    tweetsUrl,
    mroiAppUrl,
    mtaAppUrl,
    aeAppUrl,
    ratifyAppUrl,
    celonisAppUrl,
    appDetailsUrl,
    templateUrl,
    viewsAndCommentUrl,
    accessHistoryUrl,
    addLikesUrl,
    addCommentsUrl,
    uploadImageUrl,
    fav_app_url,
    userListUrl,
    publishAppUrl,
    accessUpdateUrl,
    emailForRequestApp,
    emailForStatus,
    sendMailToUsersAccessUrl,
    fontend_unique_url,
    backend_unique_url,
    sendMailToOpsUrl,
    get_Layout_Url,
    get_recommended_URL,
    add_layout_Url,
    uniqueAppUrl,
    updateTagsDatasetsUrl,
    apphandleUniqueUrl,
    usageReportUsersUrl,
    userAccessListUrl,
    signupTemplatesUrl,
    functionsUrl,
    jobRoleUrl,
    authUrl2,
    updateLayoutUrl,
    draft_app_url,
    sync_access_url,
    help_center_temp_url,
    feedbackUrl,
    allAppListUrl,
    feedbackmailUrl,
    featuremailUrl,
    incidentmailUrl,
    saveTicketMetaUrl,
    savePositionMetaUrl,
    createAzureGroupUrl,
    updateAzureGroupMembersUrl,
    powerBiWorkspaceUrl,
    powerBiReportsUrl,
    userFiltersUrl,
    usageMetricsURL,     //shashank's change
};
const cssValues = {
    cardShadow: "0px 0px 2px rgba(0, 0, 0, 0.1)",
    colors,
};

const config = {
    footerText: "Powered by Pulse and RBOne.",
    localStorageReduxStoreName: "rbAideReduxStore",
    api,
    messages,
    hardCoded,
    cssValues,
    specialFields,
    initailFilters,
    initailOptions,
};

export { config };
