import React, { useState } from "react";
import "./LargeCard.scss";
import "./AppCard.scss";

// Import Assets
import new_icon from "../../../assets/images/new-theme/new_icon.svg";
import Loader from "../Loader/Loader";
import { connect } from "react-redux";

import delete_icon from "../../../assets/images/delete_icon.svg";
const appTypeData = {
  bi_app: "Embedded Analytics",
  ml_app: "ML App",
  standalone_app: "Standalone App",
};
const LargeCard = (props) => {
  const {
    row,
    newApp,
    HandleOpenDetails,
    loadHomeApp,
    loadFav,
    showDeleteIcon,
    handleOpenApp,
    HandleAddAppToHome,
    appIdList,
    user,
    handleDeleteApp,
  } = props;

  const [Hover, setHover] = useState(false);
  return (
    <div
      onClick={handleOpenApp}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
      class={`large-card-container ${user.ui_preferences.theme}-card-background`}
    >
      {showDeleteIcon && (
        <img
          style={{
            position: "absolute",
            left: " -6px",
            top: "-6px",
            height: "20px",
            display: Hover ? "block" : "none",
          }}
          src={delete_icon}
          alt="delete"
          id={row.app_id}
          className="GA-delete-app-layout"
          onClick={handleDeleteApp}
        ></img>
      )}
      <div class="application-upper-container">
        <div class="application-category-name">
          {appTypeData[row.app_meta.app_type]}
        </div>
        <div class="icons-content-container">
          {row.approval_meta.approval_status === "published" && (
            <i
              className={`${"material-icons-round"} new-theme-home-icon ${
                Hover && row.access_details?.access_type === "private"
                  ? `lock-hover-${user.ui_preferences.theme}`
                  : Hover
                  ? "globe-hover"
                  : ""
              }`}
              id={row.app_id}
            >
              {row.access_details?.access_type === "private"
                ? "lock"
                : "public"}
            </i>
          )}
          {/* {row.approval_meta.approval_status === "published" && (
            <span
              onClick={HandleOpenDetails}
              className={`material-icons-outlined new-theme-home-icon ${
                Hover ? "info-hover" : ""
              }`}
            >
              info
            </span>
          )} */}
          {(loadFav === "loading" || loadHomeApp === "loading") && (
            <div>
              <Loader loader_size="0.6rem" margin="2px" />
            </div>
          )}
          {row.approval_meta.approval_status === "pending" &&
            loadFav !== "loading" && (
              <i
                className="material-icons-round new-app-status-icon status-schedule GA-app-card-clicked "
                id={row?.app_id}
                title="Pending"
              >
                schedule
              </i>
            )}
          {row.approval_meta.approval_status === "draft" &&
            loadFav !== "loading" && (
              <i
                className="material-icons-round new-app-status-icon status-schedule GA-app-card-clicked"
                id={row?.app_id}
                title="Draft"
              >
                drafts
              </i>
            )}
          {row.approval_meta.approval_status === "rejected" &&
            loadFav !== "loading" && (
              <i
                className="material-icons-round new-app-status-icon status-rejected GA-app-card-clicked"
                id={row?.app_id}
                title="Rejected"
              >
                error
              </i>
            )}
          {row.approval_meta.approval_status === "approved" &&
            loadFav !== "loading" && (
              <i
                className="material-icons-round new-app-status-icon status-approved GA-app-card-clicked"
                id={row?.app_id}
                title="Approved"
              >
                check_circle
              </i>
            )}
          {row.approval_meta.approval_status === "draft" &&
            loadFav !== "loading" && (
              <i
                className="material-icons-round new-app-status-icon status-schedule GA-app-card-clicked"
                id={row?.app_id}
                title="Draft"
              >
                drafts
              </i>
            )}
          {row.approval_meta.approval_status === "published" &&
            loadHomeApp !== "loading" && (
              <i
                className={`${
                  appIdList.includes(row.app_id)
                    ? "material-icons-round"
                    : "material-icons-outlined"
                } new-theme-home-icon GA-add-to-home ${
                  Hover ? "new-theme-hover-class" : ""
                }`}
                title={
                  appIdList.includes(row.app_id)
                    ? "Remove from home"
                    : "Add to home"
                }
                id={row.app_id}
                onClick={(e) => HandleAddAppToHome(e, row.app_id)}
              >
                {appIdList.includes(row.app_id) ? "home" : "add_home"}
              </i>
            )}
        </div>
      </div>

      {row.app_meta?.app_logo_type === "google_font" ? (
        <span
          class={`material-icons-outlined app-icon ${user.ui_preferences.theme}-icon`}
        >
          analytics
        </span>
      ) : (
        <img
          src={row.app_meta?.app_logo}
          className="app-icon GA-app-card-clicked"
          id={row?.app_id}
          style={{
            width: "24px",
            padding: "6px",
          }}
          alt="icon"
        />
      )}
      <div class={`text-content-container text-${user.ui_preferences.theme}`}>
        <p className={`app-name-text ${Hover ? "new-theme-hover-class" : ""}`}>
          {row.app_meta.app_name}
        </p>
        <p
          style={{
            color: user.ui_preferences.theme === "light" ? "#4e4e4e" : "#fff",
          }}
          className={`app-description-text ${user.ui_preferences.theme}-description`}
        >
          {row.app_details.description}
        </p>
        {row.approval_meta.approval_status === "published" && (
          <button
            className={
              Hover ? "details-button-RAC-hover" : "details-button-RAC"
            }
            onClick={(e) => {
              HandleOpenDetails(e, row.app_id);
            }}
          >
            Go to details
          </button>
        )}
      </div>
      {newApp && <img class="new-icon-style" src={new_icon} alt="new-app" />}
    </div>
  );
};

LargeCard.propTypes = {};
const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(LargeCard);
