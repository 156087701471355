import React, { useState } from "react";
import { Stack } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import equal from "deep-equal";

import TrackStepper from "../Components/TrackStepper";
import NotificationModal from "../../../components/AlertMsg/NotificationModal";
import ConfirmationDialog from "../Components/ConfirmationDialog";
import Loader from "../../../components/Loader/Loader";
import RegisterBodyContainer from "../Components/RegisterBodyContainer";
import FooterContainer from "../Components/FooterContainer";
//actions
import { updateAppDetails, update_MLappDetails } from "../../../redux/actions";
//hooks
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

function fetchAPI(payload) {
  const url = "/api/provisioningupdate";
  return fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

function fetchAPI2(payload) {
  const url = "/api/mlappmail";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const TrackStatus = ({
  templates,
  provisioning_details = {},
  userInfo,
  trackStatusState,
  app_id,
  appDetails,
  appFields,
  // registerNewApp,
}) => {
  // const trackStatusState = registerNewApp.MLappDetails.trackStatusState;
  const { appInfo } = useParams();
  const dispatch = useDispatch();
  const eventTracker = useAnalyticsEventTracker(
    "Track status",
    appDetails.data.app_id
  );
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [showTrackStepper, setShowTrackStepper] = useState(true);
  const handleClose = () => setModalOpen(false);
  const handleCloseStatusDialog = () => setStatusDialogOpen(false);
  const handleSubmitStatus = (status) => {
    setStatus(status);
    setStatusDialogOpen(true);
  };
  const isBtnDisabled = () =>
    loading ||
    appDetails.data.approval_meta?.approval_status === "rejected" ||
    equal(
      trackStatusState,
      appDetails.data.provisioning_details?.provisioning_meta
    );

  const getstatus = (data) => {
    if (!data) return;
    let allStatus = [];
    data.forEach((item) => {
      allStatus.push(item.provisoning_status);
    });
    if (allStatus.includes("Denied")) {
      return "denied";
    } else if (
      !allStatus.includes("In progress") &&
      !allStatus.includes("To be picked up")
    ) {
      return "completed";
    }
    return "pending";
  };
  const handleChangeStatus = (k, v) => {
    const index = trackStatusState.findIndex(
      (item) => item.resource_name === k
    );
    // setTrackStatusState([
    //   ...trackStatusState.slice(0, index),
    //   {
    //     resource_name: k,
    //     provisoning_status: v,
    //   },
    //   ...trackStatusState.slice(index + 1),
    // ]);

    dispatch(
      update_MLappDetails({
        trackStatusState: [
          ...trackStatusState.slice(0, index),
          {
            resource_name: k,
            provisoning_status: v,
          },
          ...trackStatusState.slice(index + 1),
        ],
      })
    );
    eventTracker("Provisioning status updated", k);
  };

  const handleSendMail = async (sts, msg) => {
    const data = {
      app_id,
      comments: msg || "",
      approval_status: sts,
      provisioning_status: sts,
    };
    try {
      const res = await fetchAPI2(data);
      const result = await res.json();
      if (result.status?.status !== "datareckitt-200") {
        toast.error("Unable to send mail");
      }
    } catch (error) {
      toast.error("Unable to send mail");
    }
  };
  const handleSaveChanges = async () => {
    const updated_on = moment().valueOf();
    const updated_by = userInfo.preferred_username;
    const status = getstatus(trackStatusState);
    const data = {
      app_id: app_id,
      approval_meta: appDetails.data.approval_meta,
      provisioning_details: {
        ...provisioning_details,
        updated_by,
        updated_on,
        provisioning_status: status,
        provisioning_meta: trackStatusState,
      },
    };
    try {
      setLoading(true);
      const res = await fetchAPI(data);
      const result = await res.json();
      if (result.status?.status === "datareckitt-200") {
        setMessage(result.status.statusMessage);
        setLoading(false);
        setModalOpen(true);
        dispatch(
          updateAppDetails(
            {
              type: "app_id",
              app_id,
              approval_status: appDetails.data.approval_meta?.approval_status,
            },
            "admin"
          )
        );
      }
    } catch (error) {
      toast.error("Unable to save changes");
    }
  };

  return (
    <>
      <RegisterBodyContainer height="calc(100vh - 250px);">
        <div>
          {/* <div
            style={{
              paddingTop: "10px",
              borderTop: "1px solid #ccc",
              fontWeight: 700,
              lineHeight: "24px",
              letterSpacing: "0.1px",
              // margin: "25px 0px 25px 0px",
              display: "flex",
              justifyContent: "space-between",
            }}
          > */}
          {/* Request Status Details */}
          {/* <span
              class="material-symbols-outlined"
              style={{
                cursor: "pointer",
                color: "#FF007F",
              }}
              onClick={() => setShowTrackStepper(!showTrackStepper)}
            >
              {showTrackStepper ? "expand_less" : "expand_more"}
            </span> */}
          {/* </div> */}
          <ConfirmationDialog
            open={statusDialogOpen}
            handleClose={handleCloseStatusDialog}
            handleConfirmed={handleSubmitStatus}
            status={status}
            appDetails={appDetails}
            app_id={app_id}
            app_name={appDetails.data?.app_meta?.app_name}
            trackStatus
            handleSendMail={handleSendMail}
          />
          <NotificationModal
            open={modalOpen}
            handleClose={handleClose}
            message={message}
          />
          {showTrackStepper && (
            <TrackStepper
              fields={
                appFields.app_details.project_type ===
                "Custom GenAI Infra Provisioning"
                  ? templates?.fields.slice(2)
                  : templates?.fields
                      .slice(2)
                      .filter((obj) => obj.project_type === "both")
              }
              provisioning_details={provisioning_details}
              handleChange={handleChangeStatus}
              trackStatusState={trackStatusState}
              isFieldsEditable={
                appDetails.data.approval_meta?.approval_status === "rejected" &&
                appInfo
              }
            />
          )}
        </div>
      </RegisterBodyContainer>
      <FooterContainer
        justifyContent={
          userInfo.role === "admin" || userInfo.role === "super_admin"
            ? "space-between"
            : "flex-end"
        }
      >
        {(userInfo.role === "admin" || userInfo.role === "super_admin") && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <button
              onClick={() => handleSubmitStatus("rejected")}
              disabled={
                appDetails.data.approval_meta?.approval_status === "approved" ||
                appDetails.data.approval_meta?.approval_status === "rejected" ||
                appDetails.data.approval_meta?.approval_status === "published"
              }
              className="btn-track"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                borderBottom: "1px solid #fc007f",
              }}
            >
              Send Rejection Email
            </button>
            <button
              onClick={() => handleSubmitStatus("approved")}
              disabled={
                appDetails.data.approval_meta?.approval_status === "approved" ||
                appDetails.data.approval_meta?.approval_status === "rejected" ||
                appDetails.data.approval_meta?.approval_status === "published"
              }
              className="btn-track"
              style={{
                fontSize: "14px",
                fontWeight: "400",
                borderBottom: "1px solid #fc007f",
              }}
            >
              Send Approval Email
            </button>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" spacing={2}>
          {(userInfo.role === "admin" || userInfo.role === "super_admin") && (
            <button
              className="btn btn-next"
              onClick={handleSaveChanges}
              disabled={isBtnDisabled()}
            >
              {loading ? (
                <Loader color="#ffffff" margin="0px 8px" />
              ) : (
                "Save Changes"
              )}
            </button>
          )}
        </Stack>
      </FooterContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  trackStatusState: state.registerNewApp.MLappDetails.trackStatusState,
  userInfo: state.user,
  appDetails: state.AppsData.appDetails,
  appFields: state.registerNewApp.appData.appFields,
});

export default connect(mapStateToProps)(TrackStatus);
