import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/material";
// import { Grid } from "@material-ui/core";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StepperComponent from "../Components/StepperComponent";
import MultiCheckBox from "../Components/MultiCheckBox";
import RadioBtns from "../Components/RadioBtns";
import Loader from "../../../components/Loader/Loader";
import runError from "../../../../assets/images/register-images/run-error.svg";
import runSuccess from "../../../../assets/images/register-images/run-success.svg";
import ConfirmationModal from "../../../components/AlertMsg/ConfirmationModal";
import GiveAccessComponent from "../Components/GiveAccessComponent";
import FooterContainer from "../Components/FooterContainer";
// import RegisterBodyContainer from "../Components/RegisterBodyContainer";
import "../index.scss";
import { config } from "../../../config/config";
import {
  getUniqueBackendRepo,
  getUniqueFrontendRepo,
  updateUniqueRepos,
  update_ml_app_url,
  update_appData,
  update_MLappDetails,
  update_activeStepper,
} from "../../../redux/actions";

import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";

import { BootstrapInput, HtmlTooltip, statusIconStyle } from "../style";
import { theme } from "antd";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Checkbox from "@mui/material/Checkbox";

import AutoCompleteComponent from "../../../components/AutoCompletes/AutoCompleteComponent";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function fetchAPI(param) {
  return fetch(
    `/api/uniqueappurl?app_url=https://${param}-prd${config.hardCoded.appUrl}`
  );
}

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const InputField = (props) => {
  const {
    handleChange,
    value = "",
    data,
    isFieldsEditable,
    waiting,
    isNameUnique,
    validationMsg,
    hardCoded,
    width,
    validationIconVisiblity,
    theme,
  } = props;
  const { label, key, required } = data;
  const { prefix, suffix } = hardCoded;
  // sx={{ pb: "12px" }}
  return (
    <Grid item md={width || 12} xs={12} paddingBottom={"12px"}>
      <Box display="flex">
        <div className={`heading ${theme == "light" ? "light" : "dark"}`}>
          {label} <span style={{ display: required ? "" : "none" }}>*</span>
        </div>
        {key === "requestors_comments" && (
          <Box ml="auto" textAlign="end" style={{ fontSize: "12px" }}>
            <span
              style={{
                fontSize: "12px",
                color:
                  (value && value.length > 500) || !value ? "red" : "inherit",
              }}
            >
              {value ? value.length : 0}
            </span>{" "}
            / 500
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {prefix && (
          <div
            className="desciption"
            style={{
              color: theme === "light" ? "#415A6C" : "#fff",
              // paddingRight: "10px",
            }}
          >
            {prefix}
          </div>
        )}
        <BootstrapInput
          onChange={(e) => handleChange(key, e.target.value)}
          value={value}
          placeholder={`Enter ${label}`}
          disabled={isFieldsEditable || key === "app_name"}
          fullWidth
          multiline={key === "requestors_comments"}
          rows={key === "requestors_comments" ? 4 : 1}
          title={value}
          className={
            key === "requestors_comments" || key === "app_name"
              ? ""
              : "register-config-field"
          }
        />
        {suffix && (
          <div
            className="desciption"
            style={{
              color: theme === "light" ? "#415A6C" : "#fff",
              // paddingRight: "10px",
            }}
          >
            {suffix}
          </div>
        )}
        {key === "app_handle" && (
          <HtmlTooltip
            placement="top"
            title={
              <div>APIs to consume this string for App identification.</div>
            }
          >
            <InfoOutlinedIcon
              sx={{ mx: 1, fontSize: "20px", color: "#8d9ca6" }}
            />
          </HtmlTooltip>
        )}
        {validationIconVisiblity &&
          (key === "app_url" ||
            key === "fe_repo_name" ||
            key === "be_repo_name" ||
            key === "app_handle") && (
            <>
              {isNameUnique ? (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              ) : validationMsg !== "" ? (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Already exists"
                  alt="Failed"
                />
              ) : null}
              {waiting &&
                (key === "app_url" ||
                  key === "fe_repo_name" ||
                  key === "be_repo_name" ||
                  key === "app_handle") && <Loader />}
            </>
          )}
      </Box>
      {(key === "app_url" ||
        key === "fe_repo_name" ||
        key === "be_repo_name" ||
        key === "app_handle") &&
        !isNameUnique && (
          <div className="validation_msg" style={{ color: "red" }}>
            {validationMsg}
          </div>
        )}
    </Grid>
  );
};

const get_production_env = (currentUrl) => {
  if (currentUrl.includes("uat")) {
    return "-uat";
    // include localhost
  } else if (currentUrl.includes("dev") || currentUrl.includes("localhost")) {
    return "-dev";
  } else {
    return "-prd";
  }
};

const Configuration = ({
  templates,
  userInfo,
  appFields = {},
  isFieldsEditable,
  // setActiveStepper,
  activeStepper,
  visited,
  handleSubmit,
  waiting,
  isAdminRegistering,
  appDetails,
  isChangesSubmitable,
  isChanged,
  configDetails,
  backend_repo_uniqueness,
  frontend_repo_uniqueness,
  unique_app_handle,
  theme,
  draft_app_status,
  handleDraftSubmit,
  draft_id,
  ml_app_url_status,
  isDetailsSubmitable,
  handleStepper,
}) => {
  const [isGeneralTabOpen, setIsGeneralTabOpen] = useState(true);
  const [isFrameworkTabOpen, setIsFrameworkTabOpen] = useState(true);
  const [isGenAIModuleTabOpen, setIsGenAIModuleTabOpen] = useState(true);
  const [isETLModuleTabOpen, setIsETLModuleTabOpen] = useState(true);
  const [isDataServingLayerModuleTabOpen, setIsDataServingLayerModuleTabOpen] =
    useState(true);
  const [isPILModuleTabOpen, setIsPILModuleTabOpen] = useState(true);
  const [isEnvironmentTabOpen, setIsEnvironmentTabOpen] = useState(true);
  const [isAccessTabOpen, setIsAccessTabOpen] = useState(true);
  const { appInfo } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);
  const [env, setEnv] = useState(get_production_env(currentUrl.toLowerCase()));
  const [notes, setNotes] = useState({
    llmNoteValue: "",
    integrationNoteValue: "",
  });

  let app_id;
  if (appInfo) {
    const data = appInfo.split("_");
    app_id = data[0];
  }
  const eventTracker = useAnalyticsEventTracker(
    "ML configurations",
    appDetails.data.app_id
  );
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleConfirmation = () => {
    setOpen(false);
    handleSubmit();
  };

  const isBtnDisabled = () => {
    const rslt =
      waiting ||
      ((appInfo || isAdminRegistering) && !isChangesSubmitable) ||
      ml_app_url_status.status === "loading" ||
      // checking while registering that all of these fields are unique or not
      (!(appInfo || isAdminRegistering) &&
        (backend_repo_uniqueness.status !== "success" ||
          frontend_repo_uniqueness.status !== "success" ||
          unique_app_handle.status !== "success")) ||
      // !configDetails.deployment_env?.length ||
      !(
        ml_app_url_status.unique ||
        `https://${appFields.app_url}-prd${config.hardCoded.appUrl}` ===
          appDetails.data?.app_meta?.app_url
      ) ||
      !appFields.app_url ||
      !appFields.app_details?.app_handle ||
      (configDetails.requestors_comments || "").length > 500 ||
      !appFields.app_details?.function?.length ||
      !appFields.app_details?.line_of_business?.length;
    // ||
    // !appFields.app_details?.description;
    return rslt;
  };

  const isDraftBtnDisabled = () => {
    const reqData = appDetails.data.provisioning_details;
    const be_repo_name = reqData?.backend_repository_name?.slice(4, -13);
    const fe_repo_name = reqData?.frontend_repository_name?.slice(4, -13);
    const rslt =
      waiting ||
      ml_app_url_status.status === "pending" ||
      ml_app_url_status.status === "loading" ||
      draft_app_status.status === "loading" ||
      //checking for app handle if unique or matches old one
      !(
        appFields.app_details?.app_handle &&
        (unique_app_handle.status === "success" ||
          appFields.app_details?.app_handle ===
            appDetails.data?.app_details?.app_handle)
      ) ||
      //checking only if url exist and it is unique then app repos is unique or matches old one
      !(
        appFields.app_url &&
        ml_app_url_status.unique &&
        // checking if unique or matches with old one
        (backend_repo_uniqueness.status === "success" ||
          (draft_id && configDetails.be_repo_name === be_repo_name)) &&
        (frontend_repo_uniqueness.status === "success" ||
          (draft_id && configDetails?.fe_repo_name === fe_repo_name))
      ) ||
      !appFields.app_details?.app_handle ||
      !appFields.app_url ||
      // checking if user has made any changes in already drafted app
      (draft_id && !(isChangesSubmitable || isDetailsSubmitable));
    return rslt;
  };

  // tracking the data by adding changes in isChanged state
  const updateTrackingState = useCallback(
    (key, val) => {
      if (key === "deployment_env") {
        const index = (
          (isChanged?.provisioning_details &&
            isChanged?.provisioning_details[key]) ||
          []
        ).findIndex((item) => item.env_name === val);
        if (index >= 0) {
          const filteredInfo = (
            isChanged?.provisioning_details[key] || []
          ).filter((item) => item.env_name !== val);
          // setIsChanged({
          //   ...isChanged,
          //   provisioning_details: {
          //     ...(isChanged.provisioning_details || {}),
          //     [key]: filteredInfo,
          //   },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                provisioning_details: {
                  ...(isChanged.provisioning_details || {}),
                  [key]: filteredInfo,
                },
              },
            })
          );
        } else {
          const app_url = appFields.app_url.toLowerCase().replaceAll("-", "_");
          // setIsChanged({
          //   ...isChanged,
          //   provisioning_details: {
          //     ...(isChanged.provisioning_details || {}),
          //     [key]: [
          //       ...(isChanged.provisioning_details[key] || []),
          //       {
          //         env_name: val,
          //         url: appFields.app_url,
          //         frontend_pipeline: app_url,
          //         backend_pipeline: app_url,
          //       },
          //     ],
          //   },
          // });

          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                provisioning_details: {
                  ...(isChanged.provisioning_details || {}),
                  [key]: [
                    ...(isChanged.provisioning_details[key] || []),
                    {
                      env_name: val,
                      url: appFields.app_url,
                      frontend_pipeline: app_url,
                      backend_pipeline: app_url,
                    },
                  ],
                },
              },
            })
          );
        }
      }
    },
    [
      appFields.app_url,
      isChanged,
      // setIsChanged
    ]
  );

  const updateFields = useCallback(
    (data) => {
      // setCofigDetails({ ...configDetails, ...data });
      dispatch(
        update_MLappDetails({ configDetails: { ...configDetails, ...data } })
      );
    },
    [
      configDetails,
      // setCofigDetails
    ]
  );

  const isAllowed = useCallback(
    (val) => {
      let rslt = false;
      appDetails.data.provisioning_details?.deployment_env?.some((item) => {
        if (item.env_name === val) {
          return (rslt = true);
        }
        return false;
      });
      return rslt;
    },
    [appDetails.data.provisioning_details?.deployment_env]
  );

  const handleChange = useCallback(
    (k, val) => {
      let v = "";
      if (
        k === "requestors_comments" ||
        k === "llm_model" ||
        k === "azure_databricks_notebook" ||
        k === "azure_autoscaling" ||
        k === "model_specific_database" ||
        k === "vector_datastore" ||
        k === "text_search" ||
        k === "object_store" ||
        k === "sap_checkbox" ||
        k === "simba_checkbox" ||
        k === "bard_checkbox" ||
        k === "i5_checkbox" ||
        k === "hive_checkbox" ||
        k === "nodum_checkbox" ||
        k === "marketing_checkbox" ||
        k === "supply_checkbox" ||
        k === "theia_checkbox"
      ) {
        v = val;
      } else {
        v = val.trim();
      }

      let defautlValue = {};
      if (k === "deployment_env") {
        if (v === "Development Environment") return;
        const index = (configDetails[k] || []).findIndex(
          (item) => item.env_name === v
        );

        if (
          index >= 0 &&
          //can't remove the check from deployment_env if app is already registered.
          !((appInfo || isAdminRegistering) && isAllowed(v))
        ) {
          const filteredData = configDetails[k].filter(
            (item) => item.env_name !== v
          );
          defautlValue = { ...configDetails, [k]: filteredData };
        } else if (index < 0) {
          const app_url = appFields.app_url.toLowerCase().replaceAll("-", "_");
          defautlValue = {
            ...configDetails,
            [k]: [
              ...configDetails[k],
              {
                env_name: v,
                url: appFields.app_url,
                frontend_pipeline: app_url,
                backend_pipeline: app_url,
              },
            ],
          };
        }
        if (Object.keys(defautlValue).length) {
          updateFields(defautlValue);
          if (appInfo || isAdminRegistering || draft_id) {
            updateTrackingState(k, v);
          }
        }
      } else if (k === "app_url") {
        const appUrl = v.toLowerCase().trim().replaceAll(" ", "-");
        const app_url = appUrl.replaceAll("-", "_");

        const deployment_env = [];
        (configDetails.deployment_env || []).forEach((item) => {
          deployment_env.push({
            env_name: item.env_name,
            url: appUrl,
            frontend_pipeline: app_url,
            backend_pipeline: app_url,
          });
        });
        defautlValue = {
          ...defautlValue,
          fe_repo_name: `${app_url}_Frontend`,
          be_repo_name: `${app_url}_Backend`,
          db_name: `${appUrl}`,
          deployment_env,
        };
        // setAppFields({ ...appFields, app_url: appUrl });
        dispatch(
          update_appData({ appFields: { ...appFields, app_url: appUrl } })
        );
        updateFields(defautlValue);
        if (draft_id) {
          // setIsChanged({
          //   ...isChanged,
          //   app_meta: {
          //     ...isChanged.app_meta,
          //     app_url: `https://${appUrl}-prd${config.hardCoded.appUrl}`,
          //   },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                app_meta: {
                  ...isChanged.app_meta,
                  app_url: `https://${appUrl}-prd${config.hardCoded.appUrl}`,
                },
              },
            })
          );
        }
      } else if (k === "app_handle") {
        // setAppFields({
        //   ...appFields,
        //   app_details: { ...appFields.app_details, [k]: v.trim() },
        // });
        dispatch(
          update_appData({
            appFields: {
              ...appFields,
              app_details: { ...appFields.app_details, [k]: v.trim() },
            },
          })
        );
        if (draft_id) {
          // setIsChanged({
          //   ...isChanged,
          //   app_details: {
          //     ...isChanged.app_details,
          //     [k]: v.trim(),
          //   },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                app_details: {
                  ...isChanged.app_details,
                  [k]: v.trim(),
                },
              },
            })
          );
        }
      } else if (k === "backend_stack") {
        let updatedData = [];
        if (v === "None") {
          updatedData = ["None"];
        } else if ((configDetails[k] || []).includes(v)) {
          const filteredData = configDetails[k].filter((item) => item !== v);
          if (filteredData.length) {
            updatedData = filteredData;
          } else {
            updatedData = ["None"];
          }
        } else {
          if ((configDetails[k] || []).includes("None")) {
            updatedData = [v];
          } else {
            updatedData = [...configDetails[k], v];
          }
        }
        // setCofigDetails({
        //   ...configDetails,
        //   [k]: updatedData,
        // });

        dispatch(
          update_MLappDetails({
            configDetails: {
              ...configDetails,
              [k]: updatedData,
            },
          })
        );
        if (draft_id) {
          // setIsChanged({
          //   ...isChanged,
          //   provisioning_details: {
          //     ...isChanged.provisioning_details,
          //     [k]: updatedData,
          //   },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                provisioning_details: {
                  ...isChanged.provisioning_details,
                  [k]: updatedData,
                },
              },
            })
          );
        }
      }
      // else if (k == "llm_model") {
      //   dispatch(
      //     update_MLappDetails({
      //       configDetails: {
      //         ...configDetails,
      //         [k]: val,
      //       },
      //     })
      //   );
      // }
      else {
        defautlValue = { ...configDetails, [k]: v };
        updateFields(defautlValue);
        if (appInfo || isAdminRegistering || draft_id) {
          // setIsChanged({
          //   ...isChanged,
          //   provisioning_details: {
          //     ...isChanged.provisioning_details,
          //     [k]: v,
          //   },
          // });
          dispatch(
            update_appData({
              isChanged: {
                ...isChanged,
                provisioning_details: {
                  ...isChanged.provisioning_details,
                  [k]: v,
                },
              },
            })
          );
        }
      }
    },
    [
      appFields,
      appInfo,
      configDetails,
      draft_id,
      isAdminRegistering,
      isAllowed,
      isChanged,
      // setAppFields,
      // setCofigDetails,
      // setIsChanged,
      updateFields,
      updateTrackingState,
    ]
  );
  const handleBack = () => {
    eventTracker("Back btn clicked", "clicked");
    // setActiveStepper((prev) => prev - 1);
    dispatch(update_activeStepper(activeStepper - 1));
  };

  const getHardcodedInfo = (k) => {
    if (k === "app_url")
      return {
        prefix: "https://",
        suffix: `${env}${config.hardCoded.appUrl}`,
      };
    else if (k === "fe_repo_name" || k === "be_repo_name")
      return { prefix: "GCP_", suffix: "_Data_Reckitt" };
    else if (k === "db_name")
      return { prefix: "cdh-", suffix: "-data-reckitt" };
    else if (k === "azure_cluster_name")
      return { prefix: "Azure_test-", suffix: "_Notebook-data-reckitt" };
    else if (k === "azure_git_repo")
      return { prefix: "Azure-", suffix: "-data-reckitt" };
    else return {};
  };

  const [isConfigEditable, setIsConfigEditable] = useState(() => {
    const val2 = appInfo ? false : true;
    return val2 || isAdminRegistering ? true : false;
  });

  const getRequiredComponent = (data) => {
    const { type, key, label } = data;
    if (
      (key !== "app_name" &&
        key !== "app_url" &&
        !(appInfo || isAdminRegistering) &&
        !ml_app_url_status.unique) ||
      (key === "db_name" && configDetails["provision_database"] === "None")
    )
      return;

    switch (type) {
      case "input":
        return (
          <InputField
            theme={theme}
            data={data}
            handleChange={handleChange}
            value={
              key === "app_name" || key === "app_url"
                ? appFields[key]
                : key === "app_handle"
                ? appFields.app_details?.app_handle
                : key === "azure_cluster_name"
                ? appFields.app_details?.app_handle
                : key === "azure_git_repo"
                ? appFields.app_details?.app_handle
                : configDetails[key]
            }
            isFieldsEditable={
              !(
                key === "app_url" ||
                key === "requestors_comments" ||
                key === "app_handle"
              ) ||
              isFieldsEditable ||
              appInfo ||
              isAdminRegistering
            }
            waiting={
              key === "be_repo_name"
                ? backend_repo_uniqueness.status === "loading"
                : key === "fe_repo_name"
                ? frontend_repo_uniqueness.status === "loading"
                : key === "app_handle"
                ? unique_app_handle.status === "loading"
                : // : loading
                  ml_app_url_status.status === "loading"
            }
            isNameUnique={
              key === "be_repo_name"
                ? backend_repo_uniqueness.status === "success"
                : key === "fe_repo_name"
                ? frontend_repo_uniqueness.status === "success"
                : key === "app_handle"
                ? unique_app_handle.status === "success"
                : // : isAppUrlUnique
                  ml_app_url_status.unique
            }
            validationMsg={
              key === "be_repo_name"
                ? backend_repo_uniqueness.message
                : key === "fe_repo_name"
                ? frontend_repo_uniqueness.message
                : key === "app_handle"
                ? unique_app_handle.message
                : // : validationMsg
                  ml_app_url_status.message
            }
            hardCoded={getHardcodedInfo(key)}
            width={key === "requestors_comments" ? 12 : 6}
            validationIconVisiblity={!Boolean(appInfo || isAdminRegistering)}
          />
        );
      case "radio":
        return (
          <RadioBtns
            data={data}
            handleChange={handleChange}
            value={configDetails[key]}
            isFieldsEditable={isFieldsEditable || appInfo || isAdminRegistering}
            width={
              key === "llm_model_name"
                ? 8
                : key === "azure_cluster_type"
                ? 12
                : 6
            }
            theme={theme}
          />
        );
      case "multi_checkbox":
        return (
          <MultiCheckBox
            data={data}
            handleChange={handleChange}
            value={configDetails[key]}
            isFieldsEditable={
              isFieldsEditable ||
              (key !== "deployment_env" && (appInfo || isAdminRegistering))
            }
            app_url={appFields.app_url}
            width={key === "deployment_env" ? 12 : 6}
            eventTracker={eventTracker}
          />
        );
      case "popup":
        return (
          <GiveAccessComponent
            isAppUrlUnique={ml_app_url_status.unique}
            isFieldsEditable={isFieldsEditable}
            label={label}
            eventTracker={eventTracker}
          />
        );
      case "single_checkbox":
        return (
          <Grid
            item
            xs={
              key === "llm_model" ||
              key === "model_specific_database" ||
              key === "azure_databricks_notebook" ||
              key === "vector_datastore" ||
              key === "text_search" ||
              key === "object_store"
                ? 12
                : 6
            }
            // sx={{ pb: "12px" }}
          >
            <div style={{ display: "flex" }}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                size="small"
                style={{
                  marginRight: 5,
                  padding: "0px",
                }}
                sx={{
                  "&.Mui-checked": {
                    color: "#FC007F",
                  },
                }}
                checked={configDetails[key]}
                onChange={(e) => handleChange(key, e.target.checked)}
              />
              <div
                style={{
                  color:
                    userInfo.ui_preferences.theme === "dark"
                      ? "#fff"
                      : "#282828",
                  fontSize: "14px",
                  lineHeight: "23px",
                }}
              >
                {data.label}
              </div>
            </div>
          </Grid>
        );
      case "dropdown":
        return (
          <AutoCompleteComponent
            value={configDetails[key]}
            options={data.options}
            label={data.label}
            handleChange={handleChange}
            keyField={key}
          />
        );
      default:
        return "";
    }
  };

  const setDefaultValues = useCallback(
    (data, app_url) => {
      // setCofigDetails(data);
      dispatch(update_MLappDetails({ configDetails: data }));
      // setAppFields({ ...appFields, app_url });
      dispatch(update_appData({ appFields: { ...appFields, app_url } }));
      // setVisited({ ...visited, configurations: true });
      dispatch(
        update_appData({ visited: { ...visited, configurations: true } })
      );
    },
    // [appFields, setAppFields, setCofigDetails, setVisited, visited]
    [
      appFields,
      // setCofigDetails,
      visited,
    ]
  );
  // used to fill the default values.
  useEffect(() => {
    if (
      visited.configurations ||
      appInfo ||
      (draft_id && appDetails.data.provisioning_details?.deployment_env?.length)
    )
      return;
    let defaultValues = {};
    const app_name = appFields?.app_name
      ?.toLowerCase()
      .replaceAll(" ", "_")
      .replaceAll("-", "_");
    const appName = appFields?.app_name.toLowerCase().replaceAll(" ", "-");
    templates?.fields?.forEach((item) => {
      if (item.key !== "app_name") {
        if (item.key === "deployment_env") {
          let devEnv = [];
          item.value.forEach((list) => {
            devEnv = [
              ...devEnv,
              {
                env_name: list,
                url: appName,
                frontend_pipeline: app_name,
                backend_pipeline: app_name,
              },
            ];
          });
          defaultValues = { ...defaultValues, [item.key]: devEnv };
        } else {
          defaultValues = {
            ...defaultValues,
            [item.key]: item.value,
          };
        }
      }
    });
    defaultValues = {
      ...defaultValues,
      fe_repo_name: `${app_name}_Frontend`,
      be_repo_name: `${app_name}_Backend`,
      db_name: `${appName}`,
      provisioning_access_list: [
        { email: userInfo.preferred_username, access_level: "write" },
      ],
    };

    setDefaultValues(defaultValues, appName);
    if (draft_id) {
      // setIsChanged({
      //   ...isChanged,
      //   provisioning_details: {
      //     ...isChanged.provisioning_details,
      //     defaultValues,
      //   },
      // });
      dispatch(
        update_appData({
          isChanged: {
            ...isChanged,
            provisioning_details: {
              ...isChanged.provisioning_details,
              defaultValues,
            },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appFields?.app_name,
    appInfo,
    templates?.fields,
    setDefaultValues,
    visited.configurations,
    userInfo,
    draft_id,
    appDetails.data.provisioning_details?.deployment_env?.length,
  ]);

  // run for  app url and than backend and frontend repo.
  useEffect(() => {
    let isCancelled = false;
    const handleValidation = async (value) => {
      dispatch(
        update_ml_app_url({
          status: "pending",
        })
      );
      if (!value || (value && !value.trim()) || appInfo || isAdminRegistering) {
        update_ml_app_url({
          status: "success",
        });
        return;
      } else if (
        draft_id &&
        `https://${appFields.app_url}-prd${config.hardCoded.appUrl}` ===
          appDetails.data.app_meta?.app_url
      ) {
        dispatch(
          update_ml_app_url({
            unique: true,
            message: "",
            status: "success",
          })
        );
        dispatch(updateUniqueRepos());
        return;
      }
      await timeout(1000);
      if (!isCancelled) {
        dispatch(
          update_ml_app_url({
            status: "loading",
          })
        );
        try {
          const res = await fetchAPI(value.trim());
          const result = await res.json();
          if (result.status.status === "datareckitt-200") {
            dispatch(
              update_ml_app_url({
                unique: true,
                message: result.status.statusMessage,
                status: "success",
              })
            );
            dispatch(
              getUniqueBackendRepo({
                repo_url: `GCP_${configDetails.be_repo_name}_DATA_RECKITT`,
              })
            );
            dispatch(
              getUniqueFrontendRepo({
                repo_url: `GCP_${configDetails.fe_repo_name}_DATA_RECKITT`,
              })
            );
          } else {
            dispatch(
              update_ml_app_url({
                unique: false,
                message: result.status.statusMessage,
                status: "success",
              })
            );
          }
        } catch (error) {
          console.log("error from api", error);
          // setLoading(false);
          dispatch(
            update_ml_app_url({
              unique: false,
              message: "",
              status: "success",
            })
          );
          toast.error("Oops Something went wrong. Please try later");
        }
      }
    };
    handleValidation(appFields.app_url);
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appFields.app_url]);

  const noteComponent = (item, styling = "") => {
    return ml_app_url_status.status === "success" ? (
      <Grid
        item
        xs={12}
        sx={{
          pb: "0px !important",
          pt: styling === "padding" ? "10px !important" : "0 px !important",
        }}
      >
        <div
          style={{
            color:
              userInfo.ui_preferences.theme === "dark" ? "#fff" : "#282828",
            fontSize: "12px",
            lineHeight: "23px",
            display: "flex",
            fontWeight: styling === "bold" ? "600" : "",
          }}
        >
          {item}
        </div>
      </Grid>
    ) : (
      ""
    );
  };

  useEffect(() => {
    const llmModelItem = templates?.fields?.find(
      (item) => item.key === "llm_model"
    );
    const integrationItem = templates?.fields?.find(
      (item) => item.key === "integration_with_trinity"
    );

    setNotes({
      llmNoteValue: llmModelItem ? llmModelItem.note : "",
      integrationNoteValue: integrationItem ? integrationItem.note : "",
    });
  }, [templates]);

  return (
    <>
      <ConfirmationModal
        open={open}
        handleClose={handleClose}
        handleSubmit={handleConfirmation}
        waiting={waiting}
        message={config.messages.RegisterationConfirmationText}
      />
      <div
        style={{
          height: isConfigEditable
            ? "calc(100vh - 324px)"
            : "calc(100vh - 363px)",
          overflow: "auto",
        }}
      >
        {/* GENERAL */}
        <div className="div-box">
          <div
            className={`accordion-heading ${
              theme == "light" ? "light" : "dark"
            }`}
          >
            General
          </div>
          <span
            class="material-symbols-outlined"
            style={{
              color: "#FF007F",
              cursor: "pointer",
            }}
            onClick={() => setIsGeneralTabOpen(!isGeneralTabOpen)}
          >
            {isGeneralTabOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
        {isConfigEditable ? (
          <div
            className="ml-config-div"
            style={{
              display: isGeneralTabOpen ? "block" : "none",
              paddingBottom: "20px",
            }}
          >
            <Grid container rowSpacing={2} columnSpacing={12}>
              {templates?.fields?.map((item) => {
                if (item.key === "app_name") {
                  return (
                    <>
                      {getRequiredComponent(item)}
                      <Grid item md={6} xs={12}></Grid>
                    </>
                  );
                } else if (
                  item.key === "app_url" ||
                  item.key === "app_handle"
                ) {
                  return <>{getRequiredComponent(item)}</>;
                }
              })}
            </Grid>
          </div>
        ) : (
          <div
            className="ml-config-div"
            style={{ display: isGeneralTabOpen ? "block" : "none" }}
          >
            <div className={`ml-Config-label ml-Config-${theme}`}>
              APPLICATION NAME:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {appFields.app_name}
              </span>
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              APPLICATION URL:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                https://{appFields.app_url}
                {env}.rbone.net
              </span>
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              APP HANDLE:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {appFields.app_details?.app_handle}
              </span>
            </div>
          </div>
        )}

        {/* FRAMEWORK */}
        <div className="div-box">
          <div
            className={`accordion-heading ${
              theme == "light" ? "light" : "dark"
            }`}
          >
            Framework
          </div>
          <span
            class="material-symbols-outlined"
            style={{
              color: "#FF007F",
              cursor: "pointer",
            }}
            onClick={() => setIsFrameworkTabOpen(!isFrameworkTabOpen)}
          >
            {isFrameworkTabOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
        {isConfigEditable ? (
          <div
            className="ml-config-div"
            style={{ display: isFrameworkTabOpen ? "block" : "none" }}
          >
            <Grid container rowSpacing={2} columnSpacing={12}>
              {templates?.fields?.map((item) => {
                if (
                  item.key === "frontend_framework" ||
                  item.key === "api_framework" ||
                  item.key === "provision_database" ||
                  item.key === "db_name"
                ) {
                  return <>{getRequiredComponent(item)}</>;
                }
              })}
            </Grid>
          </div>
        ) : (
          <div
            className="ml-config-div"
            style={{ display: isFrameworkTabOpen ? "block" : "none" }}
          >
            <div className={`ml-Config-label ml-Config-${theme}`}>
              FRONTEND CODE:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.frontend_framework}
              </span>
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              API FRAMEWORK:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.api_framework}
              </span>
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              PROVISION DATABASE:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.provision_database}
              </span>
            </div>

            {configDetails.provision_database !== "None" ? (
              <div className={`ml-Config-label ml-Config-${theme}`}>
                FIRESTORE COLLECTION:
                <span className={`ml-Config-value ml-Config-${theme}`}>
                  cdh-{configDetails.db_name}-data-reckitt
                </span>
              </div>
            ) : null}
          </div>
        )}

        {/* GenAI Module */}
        <div className="div-box">
          <div
            className={`accordion-heading ${
              theme == "light" ? "light" : "dark"
            }`}
          >
            GenAI Module
          </div>
          <span
            class="material-symbols-outlined"
            style={{
              color: "#FF007F",
              cursor: "pointer",
            }}
            onClick={() => setIsGenAIModuleTabOpen(!isGenAIModuleTabOpen)}
          >
            {isGenAIModuleTabOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
        {isConfigEditable ? (
          <div
            className="ml-config-div"
            style={{ display: isGenAIModuleTabOpen ? "block" : "none" }}
          >
            <Grid container rowSpacing={2} columnSpacing={12}>
              {templates?.fields?.map((item) => {
                if (item.key === "llm_model") {
                  return <>{getRequiredComponent(item)}</>;
                } else if (item.key === "llm_model_name") {
                  return configDetails["llm_model"] ? (
                    <>{getRequiredComponent(item)}</>
                  ) : (
                    ""
                  );
                }
              })}
              {noteComponent(notes.llmNoteValue)}
            </Grid>
          </div>
        ) : (
          <div
            className="ml-config-div"
            style={{ display: isGenAIModuleTabOpen ? "block" : "none" }}
          >
            <div className={`ml-Config-label ml-Config-${theme}`}>
              LLM MODEL:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.llm_model ? "Enabled" : "Disabled"}
              </span>
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              LLM MODEL NAME:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.llm_model_name}
              </span>
            </div>
          </div>
        )}

        {/* ETL */}
        <div className="div-box">
          <div
            className={`accordion-heading ${
              theme == "light" ? "light" : "dark"
            }`}
          >
            ETL
          </div>
          <span
            class="material-symbols-outlined"
            style={{
              color: "#FF007F",
              cursor: "pointer",
            }}
            onClick={() => setIsETLModuleTabOpen(!isETLModuleTabOpen)}
          >
            {isETLModuleTabOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
        {isConfigEditable ? (
          <div
            className="ml-config-div"
            style={{ display: isETLModuleTabOpen ? "block" : "none" }}
          >
            <Grid container rowSpacing={2} columnSpacing={12}>
              {templates?.fields?.map((item) => {
                if (item.key === "azure_databricks_notebook") {
                  return <>{getRequiredComponent(item)}</>;
                } else if (
                  item.key === "azure_cluster_name" ||
                  item.key === "azure_git_repo" ||
                  item.key === "azure_cluster_type" ||
                  item.key === "azure_autoscaling"
                ) {
                  return configDetails["azure_databricks_notebook"] ? (
                    <>{getRequiredComponent(item)}</>
                  ) : (
                    ""
                  );
                } else if (item.key === "azure_terminate") {
                  return configDetails["azure_databricks_notebook"] ? (
                    ml_app_url_status.status === "success" ? (
                      <Grid item xs={6} sx={{ pb: "12px" }}>
                        <div
                          style={{
                            color: "#282828",
                            fontSize: "12px",
                            lineHeight: "23px",
                            display: "flex",
                          }}
                        >
                          <div style={{ paddingRight: "5px" }}>
                            Terminate after{" "}
                          </div>
                          <>{getRequiredComponent(item)}</>{" "}
                          <div style={{ paddingLeft: "25px" }}>
                            {" "}
                            mins of activity
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  );
                }
              })}
            </Grid>
          </div>
        ) : (
          <div
            className="ml-config-div"
            style={{ display: isETLModuleTabOpen ? "block" : "none" }}
          >
            <div className={`ml-Config-label ml-Config-${theme}`}>
              AZURE DATABRICKS NOTEBOOK:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.azure_databricks_notebook
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            {configDetails.azure_databricks_notebook ? (
              <>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  CLUSTER NAME:
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.azure_cluster_name}
                  </span>
                </div>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  CLUSTER TYPE:
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.azure_cluster_type}
                  </span>
                </div>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  GIT REPOSITORY:
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.azure_git_repo}
                  </span>
                </div>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  AUTO SCALING:
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.azure_autoscaling ? "Enabled" : "Disabled"}
                  </span>
                </div>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  TERMINATE AFTER
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.azure_terminate}
                  </span>
                  min OF ACTIVITY
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        )}

        {/* Data Serving Layer*/}
        <div className="div-box">
          <div
            className={`accordion-heading ${
              theme == "light" ? "light" : "dark"
            }`}
          >
            Data Serving Layer
          </div>
          <span
            class="material-symbols-outlined"
            style={{
              color: "#FF007F",
              cursor: "pointer",
            }}
            onClick={() =>
              setIsDataServingLayerModuleTabOpen(
                !isDataServingLayerModuleTabOpen
              )
            }
          >
            {isDataServingLayerModuleTabOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
        {isConfigEditable ? (
          <div
            className="ml-config-div"
            style={{
              display: isDataServingLayerModuleTabOpen ? "block" : "none",
            }}
          >
            <Grid container rowSpacing={2} columnSpacing={12}>
              {templates?.fields?.map((item) => {
                if (item.key === "vector_datastore") {
                  return <>{getRequiredComponent(item)}</>;
                } else if (item.key === "vector_datastore_type") {
                  return configDetails[
                    item.key.split("_").slice(0, -1).join("_")
                  ] ? (
                    <>{getRequiredComponent(item)}</>
                  ) : (
                    ""
                  );
                } else if (item.key === "text_search") {
                  return <>{getRequiredComponent(item)}</>;
                } else if (item.key === "text_search_type") {
                  return configDetails[
                    item.key.split("_").slice(0, -1).join("_")
                  ] ? (
                    <>{getRequiredComponent(item)}</>
                  ) : (
                    ""
                  );
                } else if (item.key === "object_store") {
                  return <>{getRequiredComponent(item)}</>;
                } else if (item.key === "object_store_type") {
                  return configDetails[
                    item.key.split("_").slice(0, -1).join("_")
                  ] ? (
                    <>{getRequiredComponent(item)}</>
                  ) : (
                    ""
                  );
                } else if (item.key === "model_specific_database") {
                  return <>{getRequiredComponent(item)}</>;
                } else if (item.key === "database_options") {
                  return configDetails["model_specific_database"] ? (
                    <>{getRequiredComponent(item)}</>
                  ) : (
                    ""
                  );
                }
              })}
            </Grid>
          </div>
        ) : (
          <div
            className="ml-config-div"
            style={{
              display: isPILModuleTabOpen ? "block" : "none",
            }}
          >
            <div className={`ml-Config-label ml-Config-${theme}`}>
              MODEL SPECIFIC DATABASE :
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.model_specific_database ? "Enabled" : "Disabled"}
              </span>
            </div>
            {configDetails.model_specific_database ? (
              <>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  DATABASE NAME :
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.database_options}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            <div className={`ml-Config-label ml-Config-${theme}`}>
              Vector Datastore - Azure AI Search (Vector based):
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.vector_datastore ? "Enbaled" : "Disabled"}
              </span>
            </div>
            {configDetails.vector_datastore ? (
              <>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  Vector Datastore - Cluster Type:
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.vector_datastore_type}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            <div className={`ml-Config-label ml-Config-${theme}`}>
              Text search - Elastic Search (v7.17) on CosmosDB:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.text_search ? "Enbaled" : "Disabled"}
              </span>
            </div>
            {configDetails.text_search ? (
              <>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  Elastic Search - Cluster Type:
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.text_search_type}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            <div className={`ml-Config-label ml-Config-${theme}`}>
              Object Store - Cosmos Db for MongoDB (v4.0):
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.object_store ? "Enbaled" : "Disabled"}
              </span>
            </div>
            {configDetails.object_store ? (
              <>
                <div className={`ml-Config-label ml-Config-${theme}`}>
                  Object Store - Cluster Type:
                  <span className={`ml-Config-value ml-Config-${theme}`}>
                    {configDetails.object_store_type}
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        )}

        {/* Platform Integration Approvals */}
        <div className="div-box">
          <div
            className={`accordion-heading ${
              theme == "light" ? "light" : "dark"
            }`}
          >
            Platform Integration Approvals
          </div>
          <span
            class="material-symbols-outlined"
            style={{
              color: "#FF007F",
              cursor: "pointer",
            }}
            onClick={() => setIsPILModuleTabOpen(!isPILModuleTabOpen)}
          >
            {isPILModuleTabOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
        {isConfigEditable ? (
          <div
            className="ml-config-div"
            style={{
              display: isPILModuleTabOpen ? "block" : "none",
            }}
          >
            <Grid container rowSpacing={2} columnSpacing={12}>
              {templates?.fields?.map((item) => {
                if (item.key === "integration_with_trinity") {
                  return (
                    <>
                      {noteComponent(item.label, "padding")}
                      {getRequiredComponent(item)}
                      {noteComponent(notes.integrationNoteValue)}
                      <Grid item xs={12} sx={{ pb: "12px", display: "flex" }}>
                        {noteComponent("List of Datasets", "bold")}
                        {noteComponent("Data Product Owner", "bold")}
                      </Grid>
                    </>
                  );
                } else if (
                  item.key === "sap_checkbox" ||
                  item.key === "simba_checkbox" ||
                  item.key === "bard_checkbox" ||
                  item.key === "i5_checkbox" ||
                  item.key === "hive_checkbox" ||
                  item.key === "nodum_checkbox" ||
                  item.key === "marketing_checkbox" ||
                  item.key === "supply_checkbox" ||
                  item.key === "theia_checkbox"
                ) {
                  return ml_app_url_status.status === "success" ? (
                    <Grid item xs={12} sx={{ pb: "12px", display: "flex" }}>
                      {getRequiredComponent(item)}
                      <div
                        style={{
                          color:
                            userInfo.ui_preferences.theme === "dark"
                              ? "#fff"
                              : "#282828",
                          fontSize: "12px",
                          lineHeight: "23px",
                          display: "flex",
                        }}
                      >
                        {item.owner}
                      </div>
                    </Grid>
                  ) : (
                    ""
                  );
                }
              })}
            </Grid>
          </div>
        ) : (
          <div
            className="ml-config-div"
            style={{
              display: isPILModuleTabOpen ? "block" : "none",
            }}
          >
            <div className={`ml-Config-label ml-Config-${theme}`}>
              INTEGRATION WITH TRINITY:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.integration_with_trinity
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <div
              className={`ml-Config-label ml-Config-${theme}`}
              style={{ gap: "50px" }}
            >
              LIST OF DATASETS
              <span className={`ml-Config-value ml-Config-${theme}`}>
                DATA PRODUCT OWNER
              </span>
            </div>
            {templates?.fields?.map((item) => {
              if (
                item.key === "sap_checkbox" ||
                item.key === "simba_checkbox" ||
                item.key === "bard_checkbox" ||
                item.key === "i5_checkbox" ||
                item.key === "hive_checkbox" ||
                item.key === "nodum_checkbox" ||
                item.key === "marketing_checkbox" ||
                item.key === "supply_checkbox" ||
                item.key === "theia_checkbox"
              ) {
                return configDetails[item.key] ? (
                  <div
                    className={`ml-Config-label ml-Config-${theme}`}
                    style={{ gap: "50px" }}
                  >
                    {item.label}
                    <span className={`ml-Config-value ml-Config-${theme}`}>
                      {item.owner}
                    </span>
                  </div>
                ) : null;
              }
              return null;
            })}
          </div>
        )}

        {/* Environment */}
        <div className="div-box">
          <div
            className={`accordion-heading ${
              theme == "light" ? "light" : "dark"
            }`}
          >
            Environment
          </div>
          <span
            class="material-symbols-outlined"
            style={{
              color: "#FF007F",
              cursor: "pointer",
            }}
            onClick={() => setIsEnvironmentTabOpen(!isEnvironmentTabOpen)}
          >
            {isEnvironmentTabOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
        {isConfigEditable ? (
          <div
            className="ml-config-div"
            style={{ display: isEnvironmentTabOpen ? "block" : "none" }}
          >
            <Grid container rowSpacing={2} columnSpacing={12}>
              {templates?.fields?.map((item) => {
                if (
                  item.key === "fe_repo_name" ||
                  item.key === "be_repo_name" ||
                  item.key === "backend_stack" ||
                  item.key === "gcs_bucket" ||
                  item.key === "deployment_env" ||
                  item.key === "requestors_comments"
                ) {
                  return <>{getRequiredComponent(item)}</>;
                }
              })}
            </Grid>
          </div>
        ) : (
          <div
            className="ml-config-div"
            style={{ display: isEnvironmentTabOpen ? "block" : "none" }}
          >
            <div className={`ml-Config-label ml-Config-${theme}`}>
              FRONTEND REPOSITORY:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                GCP_{configDetails.fe_repo_name}_Data_Reckitt
              </span>
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              BACKEND REPOSITORY:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                GCP_{configDetails.be_repo_name}_Data_Reckitt
              </span>
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              BACKEND STACK:
              {configDetails.backend_stack?.map((item) => (
                <span
                  key={item}
                  className={`ml-Config-value ml-Config-${theme}`}
                >
                  {item}
                </span>
              ))}
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              GCS BUCKET:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.gcs_bucket}
              </span>
            </div>
            <div className={`ml-Config-label ml-Config-${theme}`}>
              REQUESTOR'S COMMENTS:
              <span className={`ml-Config-value ml-Config-${theme}`}>
                {configDetails.requestors_comments}
              </span>
            </div>
            <div
              className={`ml-Config-label ml-Config-${theme}`}
              style={{ display: isEnvironmentTabOpen ? "block" : "none" }}
            >
              {/* <Grid container rowSpacing={2} columnSpacing={12}> */}
              {templates?.fields?.map((item) => {
                if (item.key === "deployment_env") {
                  return <>{getRequiredComponent(item)}</>;
                }
              })}
              {/* </Grid> */}
            </div>
          </div>
        )}

        {/* Access */}
        <div className="div-box">
          <div
            className={`accordion-heading ${
              theme == "light" ? "light" : "dark"
            }`}
          >
            Access
          </div>

          <span
            class="material-symbols-outlined"
            style={{
              color: "#FF007F",
              cursor: "pointer",
            }}
            onClick={() => setIsAccessTabOpen(!isAccessTabOpen)}
          >
            {isAccessTabOpen ? "expand_less" : "expand_more"}
          </span>
        </div>
        <div
          className="ml-config-div"
          style={{ display: isAccessTabOpen ? "block" : "none" }}
        >
          <Grid container rowSpacing={2} columnSpacing={12}>
            {templates?.fields?.map((item) => {
              if (item.key === "access_to") {
                return <>{getRequiredComponent(item)}</>;
              }
            })}
          </Grid>
        </div>
      </div>

      {/* SUBMIT and BACK button */}
      <FooterContainer>
        <>
          {!(appInfo || isAdminRegistering) && (
            <>
              <button
                className="btn-draft"
                onClick={() => {
                  handleDraftSubmit("ML configurations");
                }}
                disabled={isDraftBtnDisabled()}
              >
                {draft_app_status.status === "loading" ? (
                  <Loader color="#ffffff" />
                ) : (
                  "Save as Draft"
                )}
              </button>
              <button
                className={`btn-back btn-back-${theme}`}
                onClick={handleBack}
                disabled={
                  draft_app_status.status === "loading" ||
                  ml_app_url_status.status === "loading" ||
                  backend_repo_uniqueness.status === "loading" ||
                  frontend_repo_uniqueness.status === "loading"
                }
                style={{
                  color: theme === "light" ? "#000000" : "#fff",
                }}
              >
                Back
              </button>
            </>
          )}
          {!isFieldsEditable && (
            <button
              onClick={
                appInfo || isAdminRegistering ? handleSubmit : handleOpen
              }
              id={(app_id || draft_id) && "GA-update-btn"}
              className="btn btn-next"
              disabled={
                isBtnDisabled() || draft_app_status.status === "loading"
              }
            >
              {waiting ? (
                <Loader color="#ffffff" margin="0px 8px" />
              ) : app_id || isAdminRegistering ? (
                "Update Request"
              ) : (
                "Submit"
              )}
            </button>
          )}
        </>
      </FooterContainer>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeStepper: state.registerNewApp.activeStepper,
  // accessManagement
  addAccessList: state.registerNewApp.accessManagement.addAccessList,
  // MLappDetails
  configDetails: state.registerNewApp.MLappDetails.configDetails,
  // statusData
  isChangesSubmitable: state.registerNewApp.statusData.isChangesSubmitable,
  isDetailsSubmitable: state.registerNewApp.statusData.isDetailsSubmitable,
  waiting: state.registerNewApp.statusData.waiting,
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  // appData
  isChanged: state.registerNewApp.appData.isChanged,
  appFields: state.registerNewApp.appData.appFields,
  visited: state.registerNewApp.appData.visited,
  theme: state.user.ui_preferences?.theme,
  appDetails: state.AppsData.appDetails,
  userInfo: state.user,
  backend_repo_uniqueness: state.register.backend_repo_uniqueness,
  frontend_repo_uniqueness: state.register.frontend_repo_uniqueness,
  unique_app_handle: state.register.unique_app_handle,
  draft_app_status: state.register.register_draft_app,
  ml_app_url_status: state.register.ml_app_url_status,
});

export default connect(mapStateToProps)(Configuration);
