import React from "react";
import { Container, Tab, Tabs } from "@mui/material";
import { useParams } from "react-router";
import { useDispatch, connect } from "react-redux";
// import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { update_activeStepper } from "../../../redux/actions";
const getSteps = (app_type) => {
  if (app_type === "ml_app") {
    return [
      "Application Type",
      "Details",
      "Configurations",
      "Track Status",
      "Access Management",
      "Usage Report",
      "Cost",
    ];
  } else if (app_type === "standalone_app") {
    return [
      "Application Type",
      "Details",
      "Configurations",
      "Access Management",
      "Usage Report",
    ];
  }
  return [
    "Application Type",
    "Details",
    "Layout",
    "Access Management",
    "Usage Report",
  ];
};

const TabComponent = ({
  activeStepper,
  // setActiveStepper,
  app_type,
  status,
  appLoading,
  app_id,
  isAdminRegistering,
  handleupdateRoute,
}) => {
  const dispatch = useDispatch();
  const { appInfo } = useParams();
  const handleChange = (event, newValue) => {
    if (isAdminRegistering || (!appInfo && app_type === "ml_app")) {
      // setActiveStepper(newValue);
      dispatch(update_activeStepper(newValue));
    } else {
      // setActiveStepper(newValue);
      dispatch(update_activeStepper(newValue));
      handleupdateRoute(newValue);
    }
  };
  return (
    <Container
      sx={(theme) => ({
        zIndex: 0,
        // zIndex: 10,
        mb: "25px",
        "&.MuiContainer-root": {
          maxWidth: "none",
        },
      })}
    >
      <Tabs
        value={activeStepper}
        onChange={handleChange}
        aria-label="Register Tabs"
        sx={(theme) => ({
          backgroundColor:
            theme.palette.mode === "dark"
              ? "transparent"
              : // : theme.palette.white.main,
                "transparent",
          // boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.08)",
          color: theme.palette.mode === "dark" ? "#fff" : "#FC007F",
          borderRadius: "5px",
          "&.MuiTabs-root": {
            minHeight: "0px !important",
            paddingTop: "10px",
            paddingBottom: "10px",
          },
          ".MuiTabs-flexContainer": {
            gap: "5px",
          },

          ".MuiTab-root": {
            minHeight: "0px",
          },
          "& .MuiButtonBase-root": {
            color:
              theme.palette.mode === "dark"
                ? "#b1b1b1"
                : // : theme.palette.dark.light,
                  "#0158aa",
            textTransform: "none",
            fontFamily: "Energy",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "13px",
            lineHeight: "20px",
            // color: "#282828",
            "@media (max-width:1399px)": {
              padding:
                app_type === "ml_app"
                  ? "0px 55px 5px 0px"
                  : app_type === "standalone_app"
                  ? "0px 90px 5px 0px"
                  : "0px 100px 5px 0px",
            },
            padding: "0px 125px 5px 0px",
            borderBottom:
              theme.palette.mode === "dark"
                ? "1px solid #b1b1b1"
                : "1px solid #0158aa80",
            "&.Mui-selected": {
              // color: theme.palette.primary.main,
              color: theme.palette.mode === "dark" ? "#fff" : "#000",
              opacity: 1,
              border: "none",
            },
            "&.Mui-disabled": {
              color: "#00000061",
            },
          },
          "& .MuiTabs-indicator": {
            // backgroundColor: theme.palette.primary.main,
            backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#000",
          },
        })}
      >
        {getSteps(app_type)
          .slice(1)
          .map((item, i) => (
            <Tab
              value={i + 2}
              label={item}
              id="register-tab"
              disabled={
                ((status === "pending" || status === "rejected") &&
                  item === "Access Management") ||
                (status !== "published" && item === "Usage Report") ||
                appLoading === "loading" ||
                ((app_id === "data-assets" || app_id === "18a252bd-b59e-4") &&
                  item !== "Usage Report")
              }
            />
          ))}
      </Tabs>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  activeStepper: state.registerNewApp.activeStepper,
});

export default connect(mapStateToProps)(TabComponent);
