import React, { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import RegisterBodyContainer from "../Components/RegisterBodyContainer";
import maintainanceCostIcon from "../../../../assets/images/maintainance_cost.png";
import infraCostIcon from "../../../../assets/images/infra_cost.png";

import "../../NewRegister/index.scss";

const CostComp = (props) => {
  let { appDetails, theme } = props;

  return (
    <RegisterBodyContainer height={`calc(100vh - 200px)`}>
      {appDetails.status === "success" && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            className="ml-config-div"
          >
            <div className="cost-style">Infra Cost</div>
            <img
              src={maintainanceCostIcon}
              style={{ height: "450px", width: "700px" }}
            />
            <div className="cost-style" style={{ fontSize: "14px" }}>
              OPEX Cost is 7000$ per month
            </div>
          </Box>
        </>
      )}
    </RegisterBodyContainer>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user,
  appDetails: state.AppsData.appDetails,
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(CostComp);
