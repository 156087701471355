import { makeStyles, styled, TextField, withStyles } from "@material-ui/core";
import { Autocomplete, InputBase } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => {
    return {
        "& .MuiInputBase-input": {
            fontFamily: "Energy",
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : theme.palette.white.main,
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
            border: "1px solid rgba(66, 89, 108, 0.25)",
            boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "5px",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "23px",
            padding: "8px",
            "&.Mui-disabled": {
                color:
                    theme.palette.mode === "dark"
                        ? theme.palette.white.main
                        : theme.palette.dark.light,
                // "-webkit-text-fill-color": `#282828 !important`,
                "-webkit-text-fill-color": `${
                    theme.palette.mode === "dark"
                        ? theme.palette.white.main
                        : theme.palette.dark.light
                } !important`,
            },
        },
        "&:focus": {},
    };
});
export const StyledAutoComplete = withStyles((theme) => {
    return {
        tag: {
            fontFamily: "Energy !important",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : theme.palette.white.main,
        },
        paper: {
            fontFamily: "Energy",
            fontSize: 13,
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
            backgroundColor:
                theme.palette.mode === "dark"
                    ? `${theme.palette.dark.light} !important`
                    : theme.palette.white.main,
            // "#282828"
        },
    };
})(Autocomplete);

export const CssTextField = withStyles((theme) => ({
    root: {
        width: "98%",
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : theme.palette.dark.light,
        backgroundColor:
            theme.palette.mode === "dark"
                ? theme.palette.dark.light
                : theme.palette.white.main,
        padding: "1px 10px",
        border: "1px solid rgba(66, 89, 108, 0.25)",
        boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        "& .MuiInputBase-root": {
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : theme.palette.dark.light,
        },
        "& label.Mui-focused": {},
        "& .MuiInput-underline:after": {
            borderBottomColor: "rgba(66, 89, 108, 0.25)",
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: "white",
            "& fieldset": {
                borderColor: "rgba(66, 89, 108, 0.25)",
            },
            "&:hover fieldset": {
                borderColor: "rgba(66, 89, 108, 0.25)",
            },
            "&.Mui-focused fieldset": {
                border: "1px solid rgba(66, 89, 108, 0.25)",
            },
        },
    },
}))(TextField);

export const useStyles = makeStyles((theme) => ({
    typographyStyle: {
        "& .MuiChip-root": {
            fontFamily: "Energy",
            fontSize: 12,
        },
        "& .MuiInput-underline:after": {
            borderBottom: 0,
        },
        "& .MuiInput-underline:before": {
            borderBottom: 0,
            transition: "none",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: "0px solid #dedede !important",
        },
        "& .Mui-focused .MuiInput-underline": {
            borderBottom: 0,
        },
        "& .MuiAutocomplete-option": {
            fontFamily: "Energy",
            fontSize: 12,
        },
        "& .MuiAutocomplete-paper": {
            fontFamily: "Energy",
            fontSize: 6,
        },
        "& .MuiInputBase-input": {
            textTransform: "capitalize",
            fontFamily: "Energy",
            fontSize: 12,
            // color: "#282828",
        },
        "& .MuiOutlinedInput-root": {
            paddingTop: "0px !important",
            paddingBottom: "0px !important",
        },
        "& .MuiAutocomplete-popupIndicator": {
            "& span": {
                "& svg": {
                    "& path": {
                        d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
                    },
                },
            },
        },
    },
    dropdownList: {
        "& .MuiAutocomplete-option": {
            fontFamily: "Energy",
            textTransform: "capitalize !important",
            fontSize: "13px",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.dark
                    : theme.palette.dark.main,
        },
    },
    select: {
        fontFamily: "Energy !important",
        fontSize: "16px!important",
        fontStyle: "normal!important",
        fontWeight: "400!important",
    },
    comboOptions: {
        fontFamily: "Energy",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
    },
    popupIndicator: {
        "& span": {
            "& svg": {
                "& path": {
                    d: "path('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')", // your svg icon path here
                },
            },
        },
    },
    inputRoot: {
        color:
            theme.palette.mode === "dark"
                ? theme.palette.white.main
                : "#282828",
        background: "#ededed",
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
            {
                paddingLeft: 26,
                fontFamily: "Energy",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "23px",
                letterSpacing: "0em",
                textAlign: "left",
                color:
                    theme.palette.mode === "dark"
                        ? theme.palette.white.main
                        : "#282828",
            },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fafafa",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fafafa",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fafafa",
        },
    },
    checkbox: {
        "& .MuiCheckbox-root": {
            padding: 0,
        },
    },
    active: {
        "&.MuiFormControl-root": {
            backgroundColor: "#282828",
            // color: "#fff",
            color: "rgba(255,255,255,0.8) !important",
        },
        "& .MuiInputBase-root": {
            // color: "#fff",
            color: "rgba(255,255,255,0.8) !important",
        },
        "& .MuiSvgIcon-root": {
            // color: "#fff",
            color: "rgba(255,255,255,0.8)",
        },
    },
    activeAuto: {
        "&.MuiFormControl-root": {
            backgroundColor: "#282828",
            color: "#fff",
            // color: "rgba(255,255,255,0.5)",
        },
        "& .MuiInputBase-root": {
            color: "#fff",
            // color: "rgba(255,255,255,0.5)",
        },
        "& .MuiSvgIcon-root": {
            color: "#fff",
            // color: "rgba(255,255,255,0.5)",
        },
    },
    unActive: {
        "&.MuiFormControl-root": {
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.dark.light
                    : "white",
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
        "& .MuiInputBase-root": {
            color:
                theme.palette.mode === "dark"
                    ? theme.palette.white.main
                    : "#282828",
        },
        "& .MuiSvgIcon-root": {
            color: "#FC007F",
        },
    },
    SecFilUnActive: {
        "&.MuiFormControl-root": {
            backgroundColor:
                theme.palette.mode === "dark" ? "#282828" : "#f6f6f6",
            color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        },
        "& .MuiInputBase-root": {
            color: "#282828",
        },
        "& .MuiSvgIcon-root": {
            color: theme.palette.mode === "dark" ? "#fff" : "#282828",
        },
    },
    raderOptions: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    countriesList: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "10px",
        gap: "5px",
    },
    regionContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    iconButton: {
        "& .MuiSvgIcon-root": {
            fontSize: "18px",
        },
    },
}));
