// Import required libraries
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

// Import styles
import "./AppCard.scss";

// Import actions
import {
  updateAppDashboardState,
  updated_total_views,
  updateLoyoutDataList,
  update_home_layout,
} from "../../redux/actions";

// Import config
import { config } from "../../config/config";

// Import custom components
import AppInfoCard from "../AppInfoCard/AppInfoCard";

// Import Hooks
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

// Import assets/images
import DeleteAlert from "../DeleteAlert/DeleteAlert";
import RequestAccessModal from "../RequestAccessModal/RequestAccessModal";
import { checkNewApp } from "../../utils/utils";
import LargeCard from "./LargeCard";
import SmallCard from "./SmallCard";

function NewAppCard(props) {
  let {
    row = {},
    type = "",
    user = {},
    handleRefreshCard,
    pageLoadState,
    showDeleteIcon = false,
    AppsData,
    layoutDataList,
    recentApps = false,
  } = props;
  let { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: appIdList } = layoutDataList;
  const [loadFav, setLoadFav] = useState("pending");
  const [AppAccess, setAppAccess] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openInfoCard, setOpenInfoCard] = useState(false);
  const [openRA, setOpenRA] = useState(false);
  const [reqState, setReqState] = useState("pending");
  const [loadHomeApp, setLoadHomeApp] = useState("pending");

  useEffect(() => {}, [user.ui_preferences.theme]);

  function hasViewAccess(person) {
    return user.preferred_username === person.user_id;
  }

  const newApp = useMemo(() => checkNewApp(row), [row]);

  useEffect(() => {
    const accessableUser = row.access_details
      ? row.access_details?.users_list?.filter(hasViewAccess)
      : [];
    const accessStatus = accessableUser?.length ? true : false;
    setAppAccess(accessStatus);
  }, []);

  const handleCloseAppInfoCard = () => {
    setReqState("pending");
    setOpenInfoCard(false);
  };
  const handleCloseRAcard = () => {
    setOpenRA(false);
  };

  const handleNavigate = (path) => {
    history.push(path);
  };

  const handleOpenInNewTab = (row) => {
    dispatch(
      updated_total_views({
        app_id: row.app_id,
      })
    );
    if (
      row.app_meta.app_type === "ml_app" &&
      !row.app_meta.app_url.startsWith("https://")
    ) {
      window.open(
        "https://" + row.app_meta.app_url,
        row.app_meta.app_window_Name
      );
    } else {
      window.open(row.app_meta.app_url, row.app_meta.app_window_Name);
    }
  };

  const HandleOpenApp = () => {
    dispatch(
      updateAppDashboardState({
        search_columns: [],
        filteredReportsList: [],
        loadingFilter: "pending",
        searchVal: "",
        filterOptions: config.initailOptions,
        filterList: config.initailFilters,
        appData: {},
        appDataStatus: "pending",
      })
    );
    if (!path.startsWith("/myapps") || recentApps) {
      // debugger;
      if (AppAccess || row?.access_details?.access_type === "public") {
        if (
          row.app_meta.app_type === "ml_app" ||
          row.app_meta.app_type === "standalone_app"
        ) {
          handleOpenInNewTab(row);
        } else if (row.app_meta.app_type === "bi_app") {
          handleNavigate(row.app_meta.app_path);
        }
      } else if (!AppAccess) {
        setOpenRA(true);
      }
    } else if (path.startsWith("/myapps")) {
      if (row.approval_meta.approval_status === "draft") {
        handleNavigate("/register/draft" + row.app_meta.app_path);
      } else {
        handleNavigate("/register" + row.app_meta.app_path + "/details");
      }
    }
  };

  const HandleAddAppToHome = async (e, id) => {
    e.stopPropagation();
    setLoadHomeApp("loading");
    let data = {
      user_id: user.preferred_username,
    };
    const gaEventTracker = useAnalyticsEventTracker("AddOrRemFromHome");
    if (!appIdList.includes(id)) {
      data = {
        ...data,
        sequence_of_app: [id, ...appIdList],
      };
      gaEventTracker("Add", `app_id = ${id}`);
    } else {
      const reqList = appIdList.filter((item) => item !== id);
      data = { ...data, sequence_of_app: reqList };
      gaEventTracker("Remove", `app_id = ${id}`);
    }
    dispatch(updateLoyoutDataList(data.sequence_of_app));
    dispatch(update_home_layout(data));
  };

  const handleDelete = (e, row) => {
    setLoadFav("loading");
    let id = row.app_id;
    let status = row.approval_meta.approval_status;

    let url = "/api/appregister?app_id=" + id + "&approval_status=" + status;
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        console.groupCollapsed("requesting", url);
        console.log("REPSONSE -> ", response);
        if (response.status === 200) {
          return response.json();
        } else {
          let error = response.statusText;
          let errorCode = response.status;
          console.log("ERROR -> ", errorCode);
          toast.error("Unable to Delete Application.");
          setLoadFav("pending");
        }
      })
      .then((json) => {
        console.log("JSON -> ", json);
        console.groupEnd();
        if (json?.errorCode) {
          console.log("ERROR=>", json.errorCode);
          setLoadFav("error");
          handleClose();
          toast.error("Delete Request Failed !");
        } else {
          if (json?.status.status === "datareckitt-200") {
            setLoadFav("loading");
            handleClose();
            // toast.success(json?.status.statusMessage);
            handleRefreshCard();
          } else {
            setLoadFav("error");
            handleClose();
            toast.error(json?.status.statusMessage);
          }
        }
      });
  };

  const handleClose = () => {
    setOpenDelete(false);
  };
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpenDelete(true);
  };

  const HandleOpenDetails = (e) => {
    e.stopPropagation();
    setOpenInfoCard(true);
  };

  useEffect(() => {
    if (pageLoadState === "success") {
      setLoadFav("success");
    } else if (pageLoadState === "error") {
      setLoadFav("error");
      toast.error("Couldn't update ! Please try Again.");
    }
  }, [pageLoadState]);

  useEffect(() => {
    if (AppsData?.refreshedLayoutApps?.status === "success") {
      setLoadHomeApp("success");
    }
  }, [AppsData.refreshedLayoutApps]);

  return (
    <>
      <div>
        {openDelete && (
          <DeleteAlert
            open={openDelete}
            handleClose={handleClose}
            handleDelete={handleDelete}
            row={row}
            loadFav={loadFav}
          />
        )}
        {type === "add" && (
          <div key="add-apps">
            <Link
              style={{
                height:
                  user.ui_preferences.grid_size === "large_grid"
                    ? "212px"
                    : "142px",
              }}
              class="new-theme-add-card"
              to="/register"
            >
              <i className="material-icons-round application-icon-1">
                dashboard_customize
              </i>
              <p className="application-title-1">Create New App</p>
            </Link>
          </div>
        )}
        {type !== "add" && user.ui_preferences.grid_size === "large_grid" && (
          <LargeCard
            HandleOpenDetails={HandleOpenDetails}
            loadHomeApp={loadHomeApp}
            newApp={newApp}
            loadFav={loadFav}
            appIdList={appIdList}
            showDeleteIcon={showDeleteIcon}
            row={row}
            handleOpenApp={HandleOpenApp}
            HandleAddAppToHome={HandleAddAppToHome}
            handleDeleteApp={handleOpen}
          />
        )}
        {type !== "add" &&
          (user.ui_preferences.grid_size === "small_grid" ||
            user.ui_preferences.grid_size === "list") && (
            <SmallCard
              HandleOpenDetails={HandleOpenDetails}
              loadHomeApp={loadHomeApp}
              newApp={newApp}
              loadFav={loadFav}
              appIdList={appIdList}
              showDeleteIcon={showDeleteIcon}
              row={row}
              handleOpenApp={HandleOpenApp}
              HandleAddAppToHome={HandleAddAppToHome}
              handleDeleteApp={handleOpen}
            />
          )}
      </div>
      {openInfoCard && (
        <AppInfoCard
          open={openInfoCard}
          handleClose={handleCloseAppInfoCard}
          appDetails={row}
          setOpenRA={setOpenRA}
          reqState={reqState}
          setReqState={setReqState}
        />
      )}
      {openRA && (
        <RequestAccessModal
          openRA={openRA}
          handleClose={handleCloseRAcard}
          appDetails={row}
          setOpenRA={setOpenRA}
          reqState={reqState}
          setReqState={setReqState}
        />
      )}
    </>
  );
}

NewAppCard.propTypes = {
  name: PropTypes.string,
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  AppsData: state.AppsData,
  layoutDataList: state.AppsData.layoutDataList,
});

export default connect(mapStateToProps)(NewAppCard);
