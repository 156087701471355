// Import required libraries
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@mui/material";
import ReactGA from "react-ga";
// Import styles
import "./App.scss";

// Import routes
import Routes from "./routes/Routes";

// Import action creators
import { updateUserInfo, fetchRouteData } from "./redux/actions";

// Import utils
import { config } from "../js/config/config";
import { makeDefaultResponseJson } from "./utils/utils";

//Import custom components
import Loader from "./components/Loader/Loader";
import ImageLoader from "./components/ImageLoader/ImageLoader";
import Security_pana from "../assets/images/Security-pana.svg";
import SessionExpired from "./screens/SessionExpired/SessionExpired";
import Login from "./screens/Login/Login";
import LargeCard from "./components/AppCard/LargeCard";

const defaultTheme = (theme) =>
  createTheme({
    palette: {
      mode: theme || "light",
      font: {
        fontFamily: "Energy !important",
        weight: {
          thin: 200,
          medium: 400,
          strong: 60,
        },
        color: {
          dark: "#000 !important",
          secondary: "#4E4E4E !important",
          tertiary: "#979797 !important",
        },
      },
      colors: {
        teal: "#219793",
        darkBlue: "#0058AA",
        black: "#282828",
        pink: "#FC007F",
      },
      dark: {
        main: "#000000",
        light: "#282828",
      },
      primary: {
        // main: "#ea3592",
        main: "#FC007F",
      },
      white: {
        main: "#fff",
      },
      background: {
        default: theme === "dark" ? "#000000" : "#fff",
        paper: theme === "dark" ? "#000000" : "#fff",
      },
    },
  });

function App(props) {
  let { user, updateUserInfo, theme = "light" } = props;
  const history = useHistory();
  const [authResolved, setAuthResolved] = useState(false);
  // User authentication
  useEffect(() => {
    if (history.location.pathname !== "/sessionExpired") {
      let url = config.api.authUrl;
      fetch(url)
        .then((response) => {
          console.groupCollapsed("requesting", url);
          console.log("REPSONSE -> ", response);
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 401) {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              ...makeDefaultResponseJson(),
              error,
              errorCode,
              status: {},
            };
          } else {
            let error = response.statusText;
            let errorCode = response.status;
            console.log("ERROR -> ", error);
            return {
              ...makeDefaultResponseJson(),
              error,
              errorCode,
              status: {},
            };
          }
        })
        .then((json) => {
          console.log("JSON -> ", json);
          console.groupEnd();
          if (
            json.status.statusCode === "datareckitt-200" ||
            json.status.statusCode === "auth-200"
          ) {
            updateUserInfo({
              name: json.data.name,
              preferred_username: json.data.preferred_username,
              isAuth: true,
              error: null,
              status: null,
              role: json.data.role,
              active_status: json.data.active_status,
              filteredApps: { data: [], status: "loading" },
              viewByFilter: "app_category",
              ui_preferences: {
                theme: json.data?.ui_preferences?.theme || "light",
                grid_size: json?.data.ui_preferences?.grid_size || "large_grid",
              },
              report_fullscreen: false,
              profile_meta: json.data.profile_meta,
              user_interests: json.data.user_interests,
              user_type: json.data.user_type,
            });
            if (json.data.active_status === "inactive") {
              history.push("/under-maintenance");
            }
            const location = window.location.pathname;
            setAuthResolved(true);
            if (
              json.data.user_type === "RETURNING" ||
              json.data.user_type === "NEW"
            ) {
              history.push("/account-setup");
            } else {
              history.push(location);
            }
          }
          if (json.errorCode === 401) {
            updateUserInfo({
              name: "",
              preferred_username: "",
              isAuth: false,
              error: null,
            });
            window.localStorage.removeItem(config.localStorageReduxStoreName);
            setAuthResolved(true);
            window.location.href = "/auth/sign_in";
          } else if (json.errorCode === 500) {
            updateUserInfo({
              error: json.error,
              errorCode: json.errorCode,
            });
            setAuthResolved(true);
            window.location.href = "/error";
          } else if (json.errorCode) {
            updateUserInfo({
              error: json.error,
              errorCode: json.errorCode,
            });
            setAuthResolved(true);
            window.location.href = "/login";
          }
        });
    }
  }, []);

  // const dispatch = useDispatch();
  // useEffect(() => {
  //     if (history.location.pathname !== "/sessionExpired") {
  //         dispatch(fetchRouteData());
  //     }
  // }, []);

  // Unauthorized user
  useEffect(() => {
    if (user.error === "UNAUTHORIZED" || user.errorCode === 401) {
      updateUserInfo({ isAuth: false });
      window.localStorage.removeItem(config.localStorageReduxStoreName);
      window.location.href = config.api.signInUrl;
    }
  }, [user.error]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Route path="/sessionExpired" component={SessionExpired} exact />
      <Route path="/largecard" component={LargeCard} exact />
      {authResolved ? (
        <>
          <ToastContainer />
          <ThemeProvider theme={defaultTheme(theme)}>
            <MuiThemeProvider theme={defaultTheme(theme)}>
              <Routes />
            </MuiThemeProvider>
          </ThemeProvider>
        </>
      ) : (
        <ImageLoader
          ImgHeight="300px"
          LoadImg={Security_pana}
          message={config.messages.authenticatingUserMessage}
        />
      )}
    </>
  );
}

App.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
  allApps: state.AppsData.allApps,
  allRoutes: state.AppsData.allRoutes,
  theme: state.user.ui_preferences?.theme,
});

const mapDispatchToProps = { updateUserInfo };

export default connect(mapStateToProps, mapDispatchToProps)(App);
