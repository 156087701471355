import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import NotificationModal from "../../../components/AlertMsg/NotificationModal";
import Loader from "../../../components/Loader/Loader";
import { publishapp } from "../../../apiServices";
import ConfirmationDialog from "./ConfirmationDialog";
import { config } from "../../../config/config";
import { ownerList } from "../../../utils/utils";
import { publishappStyle } from "../style";
import "../index.scss";
import { update_statusData } from "../../../redux/actions";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const StatusBar = ({ appDetails, userInfo, appFields, theme }) => {
  const { app_meta, approval_meta } = appDetails.data;
  const { approval_status } = approval_meta || {};
  const history = useHistory();
  const [waiting, setWaiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setMsg("");
    history.push("/explore");
  };
  const handleStatus = (statusInfo) => {
    setStatus(statusInfo);
    setStatusDialogOpen(true);
  };
  const handleCloseStatusDialog = () => setStatusDialogOpen(false);
  const handleSubmitStatus = (status) => {
    setStatusDialogOpen(false);
  };

  const isPublisedBtnDisabled = () =>
    waiting ||
    appDetails.status === "loading" ||
    (app_meta?.app_type === "ml_app" && approval_status !== "approved");

  const handlePublishApp = async () => {
    setWaiting(true);
    setMsg("");
    const currentDate = moment().valueOf();
    let data = {
      app_id: appDetails.data.app_id,
      access_details: appDetails.data.access_details,
      approval_meta: {
        updated_on: currentDate,
        updated_by: userInfo.preferred_username,
        approval_status: "published",
      },
    };
    if (!appDetails.data.app_meta.published_on) {
      data = { ...data, published_on: currentDate };
    }
    const { isSuccess, statusMsg } = await publishapp(data);
    if (isSuccess) {
      dispatch(update_statusData({ dirty: false }));
      setMsg(statusMsg || "Your Application has been successfully published!");
      setOpen(true);
    } else {
      toast.error("Opps! unable to publish app");
    }
    setWaiting(false);
  };

  return (
    <>
      <ConfirmationDialog
        open={statusDialogOpen}
        handleClose={handleCloseStatusDialog}
        handleConfirmed={handleSubmitStatus}
        status={status}
        appFields={appFields}
        appDetails={appDetails}
        app_id={appDetails.data.app_id}
        app_name={appDetails.data?.app_meta?.app_name}
      />
      <NotificationModal open={open} handleClose={handleClose} message={msg} />
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          textAlign: "start",
          mb: 1,
          zIndex: 10,
          maxWidth: "none",
        }}
      >
        {/* REMOVE APPNAME & STATUS */}
        {/* <div className="app-name-status">
          <div className="app-name">{app_meta?.app_name || ""}</div>
          {approval_meta &&
            (approval_status !== "pending" ||
              (approval_status === "pending" &&
                userInfo.role !== "admin" &&
                userInfo.role !== "super_admin") ||
              app_meta?.app_type === "ml_app") && (
              <button
                className={`btn-status ${
                  approval_status === "approved"
                    ? "btn-approve"
                    : approval_status === "rejected"
                    ? "btn-rejected"
                    : approval_status === "published"
                    ? "btn-published"
                    : "btn-pending"
                }`}
              >
                {approval_status || ""}
              </button>
            )}
          {/* <div className="data-quote" style={{ lineHeight: "15px" }}>
                        {config.messages.dataReckittQuote}
                    </div> */}
        {/* </div>  */}
        <Box display="flex" alignItems="center" top="30px" right="45px" position="absolute" >
          {approval_meta &&
            (userInfo.role === "admin" || userInfo.role === "super_admin") &&
            approval_status === "pending" &&
            app_meta.app_type !== "ml_app" && (
              <>
                <button
                  className="profile-btn-cancel profile-btn"
                  style={{ marginRight: 20 }}
                  onClick={() => handleStatus("rejected")}
                  title="Reject"
                >
                  <ClearIcon />
                </button>
                <button
                  className="profile-btn-approve profile-btn"
                  onClick={() => handleStatus("approved")}
                  title="Approve"
                >
                  <CheckIcon />
                </button>
                {/* <button
                  className={`btn-status ${theme}-btn-decline`}
                  style={{ marginRight: 20 }}
                  onClick={() => handleStatus("rejected")}
                >
                  Reject
                </button>
                <button
                  className="btn-status btn-approve"
                  onClick={() => handleStatus("approved")}
                >
                  Approve
                </button> */}
              </>
            )}
          {approval_meta &&
            approval_status === "approved" &&
            ((ownerList(appDetails.data) || []).includes(
              userInfo.preferred_username
            ) ||
              userInfo.role === "super_admin") && (
              <button
                style={publishappStyle}
                onClick={handlePublishApp}
                disabled={isPublisedBtnDisabled()}
              >
                {waiting ? <Loader margin="0px 8px" /> : "Publish App"}
              </button>
            )}
        </Box>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  appDetails: state.AppsData.appDetails,
  userInfo: state.user,
});

export default connect(mapStateToProps)(StatusBar);
