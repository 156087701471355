import React, { useEffect, useMemo, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import equal from "deep-equal";
import { useHistory, useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import { Container } from "@mui/material";
import moment from "moment";
import Loader from "../../components/Loader/Loader";
import LayoutTopSideBottom from "../../layouts/LayoutTopSideBottom/LayoutTopSideBottom";
import NotificationModal from "../../components/AlertMsg/NotificationModal";
import StepperComponent from "./Components/StepperComponent";
import TabComponent from "./Components/TabComponent";
import StatusBar from "../NewRegister/Components/StatusBar";
import RegisterAlert from "../../components/AlertMsg/RegisterAlert";
import semiCircle from "../../../assets/images/register-images/semiCircle.svg";
// import DetailsDrawer from "./Components/DetailsDrawer/DetailsDrawer.js";
import {
  RegisterNewAppSkeletons,
  UpdateAppSkeleton,
  UsageMaticsSkeletons,
} from "../Register/components/Skeletons";
import LeavePageBlocker from "../../components/LeavePageBlocker";
//pages
import AppDetailsComponent from "./pages/AppDetailsComponent";
import ApplicationCategory from "./pages/ApplicationCategory";
import Configuration from "./pages/Configuration";
import ConfigurationContent from "./pages/ConfigurationContent";
import AccessManagement from "./pages/AccessManagement";
import AppTypeComponent from "./pages/AppTypeComponent.jsx";
import StandaloneConfig from "./pages/StandaloneConfig";
import TrackStatus from "./pages/TrackStatus";
import UsageReport from "./pages/UsageReport";
import CostComp from "./pages/Cost";
import ApplicationTemplate from "../Register/pages/ApplicationTemplate";
import LayoutSelection from "./pages/LayoutSelection";

import { useStylesRegisterStatic } from "./style";

import {
  cleanAppDetails,
  cleanTempState,
  clean_register_states,
  getAppDetails,
  getRegisterAppTemplate,
  get_unique_app_handle,
  updateAppDetails,
  updateDraftstatus,
  updateTagsDatasets,
  update_app_handle,
  validateAppUrl,
  update_statusData,
  update_appData,
  update_accessManagement,
  update_MLappDetails,
  update_BIappDetails,
  clean_registerNewApp_state,
  update_activeStepper,
  updateReportTemplate,
} from "../../redux/actions";
import {
  sendmailRequest,
  sendmailToOpsRequest,
  update_draft_apps,
} from "../../apiServices";
import { config } from "../../config/config";
import {
  getFilteredOptions,
  isEqualObject,
  isUrlMatch,
  ownerList,
  shortedData,
  updateCatState,
  updateMiddleSubcatState,
  UpdatePageView,
  updateSubState,
} from "../../utils/utils";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import {
  getRequiredComponent,
  getTemplateStringInNumber,
} from "../Register/components/DashboardTemplate/utils";

import "./index.scss";
import CreateLayout from "./pages/CreateLayout";
import AppLayout from "./pages/AppLayout";
import AppTemplate from "./pages/AppTemplate";
import EditPreviewToggle from "./Components/EditPreviewToggle";
import { act } from "react-dom/test-utils";

function fetchAPI(param) {
  return fetch(`/api/uniqueappname?app_name=${param}`);
}
function fetchAPIRegister(payload, app_id) {
  const url = "/api/appregister";
  return fetch(url, {
    method: app_id ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// const initailsValues = {
//   app_details: {},
// };

const RegisterApp = ({
  match,
  userInfo,
  register_app_template,
  appDetails,
  unique_app_handle,
  theme = "light",
  appTemplateState,
  configDetails,
  categoryInfo,
  categoryInfoLocal,
  dirty,
  isAdminRegistering,
  isChanged,
  appFields,
  addAccessList,
  access_type,
  editable_layout,
  selectedWorkspace,
  activeStepper,
  userRole,
  isDetailsSubmitable,
  appInfoID,
  template_val,
  templateData,
  isStepperHidden,
  visited,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const history = useHistory();
  const oldThemeValue = useRef(theme);
  useEffect(() => {
    const unlisten = history.listen((location) => {
      if (!location.pathname.includes("register")) {
        dispatch(clean_registerNewApp_state());
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const dispatch = useDispatch();

  const { appInfo, draftId, tab } = useParams();
  const { showSubCatPage, template_no, temp_img_data } = appTemplateState;
  // const [activeStepper, setActiveStepper] = useState();
  const [validationMsg, setValidationMsg] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const [openSubmitError, setOpenSubmitError] = useState(false);
  const [open, setOpen] = useState(false);
  const [draftApp, setDraftApp] = useState(false);
  const location = useLocation();

  const eventTracker = useAnalyticsEventTracker(
    appInfo || isAdminRegistering ? "Update app" : "Registration",
    appDetails.data.app_id
  );

  const classes = useStylesRegisterStatic();

  let app_id = null;
  let draft_id = null;
  let approval_status = null;
  // taking the approval_status & app_id from the params of the route of apps which r not draft one.
  if (appInfo) {
    const data = appInfo.split("_");
    app_id = data[0];
    approval_status = data[1];
  }
  // taking the approval_status & route from the route of the draft apps
  if (draftId) {
    const data = draftId.split("_");
    draft_id = data[0];
    approval_status = data[1];
  }

  useEffect(() => {
    if (approval_status === "pending") {
      dispatch(update_statusData({ editLayout: false }));
    }
  }, [editable_layout]);
  /*
checking if app is editable or not:
can't edit it in these situations
1. if it is rejected or in pending state
2. if not the owner of the app (only super_admin or owner can edit it)
*/
  const isFieldsEditable = useMemo(() => {
    const isEditable =
      (appInfo || isAdminRegistering) &&
      appDetails.data.approval_meta &&
      (appDetails.data.approval_meta.approval_status === "pending" ||
        !(
          (ownerList(appDetails.data) || []).includes(
            userInfo.preferred_username
          ) || userInfo.role === "super_admin"
        ) ||
        (appDetails.data.app_meta?.app_type !== "bi_app" &&
          (appDetails.data.approval_meta?.approval_status === "rejected" ||
            appDetails.data.approval_meta?.approval_status === "pending")));
    return isEditable;
  }, [
    appDetails.data,
    appInfo,
    isAdminRegistering,
    userInfo.preferred_username,
    userInfo.role,
  ]);

  useEffect(() => {
    if (app_id !== null) {
      dispatch(update_appData({ appInfoID: app_id }));
    }
  }, [appInfoID]);

  const handleStepper = (i) => {
    // setActiveStepper(i + 1);
    dispatch(update_activeStepper(i + 1));
  };
  const handleCloseAlert = () => {
    setOpen(false);
    setAlertMsg("");
    if (draftId || draftApp) {
      history.push("/myapps");
    } else if (appFields.app_type === "bi_app") {
      if (userInfo.role === "user") {
        dispatch(clean_registerNewApp_state());
        history.push("/explore");
      } else {
        if (userInfo.role === "admin" || userInfo.role === "super_admin") {
          dispatch(
            getAppDetails({
              type: "app_id",
              app_id: appInfoID || draftId,
              approval_status: approval_status || "approved",
            })
          );
          if (approval_status) {
            history.push(`/register/${appInfoID}_${approval_status}/details`);
          } else {
            history.push(`/register/${appInfoID}_approved/details`);
          }
          dispatch(clean_registerNewApp_state());
        }
      }
    } else if (appFields.app_type === "ml_app") {
      if (userInfo.role === "user") {
        dispatch(clean_registerNewApp_state());
        history.push("/explore");
      } else {
        if (isAdminRegistering) {
          history.push(`/register/${appInfoID}_pending/details`);
        } else {
          dispatch(clean_registerNewApp_state());
          history.push("/explore");
        }
      }
      // setActiveStepper((prev) => prev + 1);
      // dispatch(update_activeStepper(activeStepper + 1));
    } else if (appFields.app_type !== "ml_app") {
      if (userInfo.role === "user") {
        dispatch(clean_registerNewApp_state());
        history.push("/explore");
      } else {
        if (userInfo.role === "admin" || userInfo.role === "super_admin") {
          dispatch(
            getAppDetails({
              type: "app_id",
              app_id: appInfoID || draftId,
              approval_status: "approved",
            })
          );
          history.push(`/register/${appInfoID}_approved/details`);
          dispatch(clean_registerNewApp_state());
        }
      }
    }
  };
  const navigateWithoutSaveTrack = (res = "continued") => {
    eventTracker("Navigate without saving", res);
  };

  const handleCloseSubmitError = () => {
    // if (activeStepper === 3 && ) {
    // history.push("/");
    // }
    setOpenSubmitError(false);
    setAlertMsg("");
  };

  // this function is tracking & updating the data of the form fields.
  const handleChange = (key, value) => {
    if (key === "app_type" && (isAdminRegistering || appInfo || draftId))
      return;
    dispatch(update_appData({ appFields: { ...appFields, [key]: value } }));
    if (draftId || isAdminRegistering || appInfo) {
      dispatch(
        update_appData({
          isChanged: {
            ...isChanged,
            app_meta: { ...isChanged.app_meta, [key]: value },
          },
        })
      );
    }
  };

  const getDetailsTemplates = (type) => {
    const templates = register_app_template.data.register_app_template;
    const apptypes = templates.fields?.find((item) => item.key === "app_type");
    const selectedType = apptypes.options?.find(
      (item) => item.key === appFields.app_type
    );
    if (selectedType) {
      if (type === "details") {
        return (selectedType || {}).subform_templates[0];
      } else if (type === "configuration_content") {
        return (selectedType || {}).subform_templates[2];
      }
      return (selectedType || {}).subform_templates[1];
    } else {
      return null;
    }
  };

  // used to remove the extra fields from the category object
  const getCategories = (data) => {
    let requiredData = [];
    data.forEach((item) => {
      const filteredData = _.omit(item, "isCatEditable");
      let sub_category = [];
      filteredData.sub_category.forEach((sub) => {
        const filteredList = _.omit(sub, "isSubCatEditable");
        let report = [];
        if (sub.hasOwnProperty("sub_category")) {
          filteredList.sub_category.forEach((rep) => {
            const filteredReps = _.omit(rep, "isSubCatEditable");
            report = [...report, filteredReps];
          });
          sub_category = [
            ...sub_category,
            { ...filteredList, sub_category: report },
          ];
        } else sub_category = [...sub_category, filteredList];
      });
      requiredData = [...requiredData, { ...filteredData, sub_category }];
    });
    return requiredData;
  };
  const getHardCodedData = (info) => {
    if (info.env_name === "Production Environment") return "PRD";
    else if (info.env_name === "UAT Environment") return "UAT";
    return "DEV";
  };

  const getSubmitableData = (data) => {
    const reqData = [];
    data?.forEach((item) => {
      const env = getHardCodedData(item);
      reqData.push({
        env_name: item.env_name,
        url: `https://${item.url}-${env.toLowerCase()}${
          config.hardCoded.appUrl
        }`,
        frontend_pipeline: `GCP_${env === "PRD" ? "PROD" : env}_${
          item.frontend_pipeline
        }_Frontend_CI_CD`,
        backend_pipeline: `GCP_${env === "PRD" ? "PROD" : env}_${
          item.backend_pipeline
        }_Backend_CI_CD`,
      });
    });
    return reqData;
  };
  // this function is used to auto fillup configuration page data after entering in it from detail page while registering
  const setDefaultConfigData = (data = {}) => {
    let defaultValue = {};
    const be_repo_name = data.backend_repository_name?.slice(4, -13);
    const fe_repo_name = data.frontend_repository_name?.slice(4, -13);
    const db_name = data.db_name?.slice(4, -13);
    const deployment_env = [];
    data.deployment_env?.forEach((item) => {
      if (item.env_name === "Production Environment") {
        deployment_env.push({
          env_name: item.env_name,
          backend_pipeline: item.backend_pipeline.slice(9, -14),
          frontend_pipeline: item.frontend_pipeline.slice(9, -15),
          url: item.url.slice(8, -14),
        });
      } else {
        deployment_env.push({
          env_name: item.env_name,
          backend_pipeline: item.backend_pipeline.slice(8, -14),
          frontend_pipeline: item.frontend_pipeline.slice(8, -15),
          url: item.url.slice(8, -14),
        });
      }
    });
    defaultValue = {
      ...data,
      be_repo_name,
      fe_repo_name,
      deployment_env,
      db_name,
    };

    dispatch(update_MLappDetails({ configDetails: defaultValue }));
  };

  const handleSaveTagsDatasets = () => {
    const data = {
      associated_datasets: appFields.app_details?.associated_datasets || [],
      tags: appFields.app_details?.tags || [],
    };
    dispatch(updateTagsDatasets(data));
  };
  const setLocalToCatInfoData = (infoStateDummy, localStateDummy) => {
    let infoState = infoStateDummy;
    let localState = localStateDummy;
    localStateDummy.forEach((item, catInd) => {
      if (item.isCatEditable && item.sub_category_name) {
        const reqLocalData = {
          ...localState[catInd],
          isCatEditable: false,
        };
        const reqInfoData = {
          ...localState[catInd],
          isCatEditable: false,
          sub_category: infoState[catInd].sub_category,
        };
        infoState = updateCatState(infoState, catInd, reqInfoData);
        localState = updateCatState(localState, catInd, reqLocalData);
      }
      item?.sub_category?.forEach((list, subCatIndex) => {
        if (list.hasOwnProperty("url")) {
          const checkForUrl = isUrlMatch(list.bi_application, list.url);
          if (
            list.sub_category_name !== "" &&
            list.url !== "" &&
            checkForUrl &&
            list.isSubCatEditable
          ) {
            const reqLocalData = {
              ...localState[catInd].sub_category[subCatIndex],
              isSubCatEditable: false,
            };
            infoState = updateSubState(
              infoState,
              catInd,
              -1,
              reqLocalData,
              subCatIndex
            );
            localState = updateSubState(
              localState,
              catInd,
              -1,
              reqLocalData,
              subCatIndex
            );
          }
        } else {
          if (list.isSubCatEditable && list.sub_category_name) {
            const reqLocalData = {
              ...localState[catInd].sub_category[subCatIndex],
              isSubCatEditable: false,
            };
            infoState = updateMiddleSubcatState(
              infoState,
              catInd,
              subCatIndex,
              reqLocalData
            );
            localState = updateMiddleSubcatState(
              localState,
              catInd,
              subCatIndex,
              reqLocalData
            );
          }
          list.sub_category.forEach((rep, repIndex) => {
            const checkForUrl = isUrlMatch(rep.bi_application, rep.url);
            if (
              rep.sub_category_name !== "" &&
              rep.url !== "" &&
              checkForUrl &&
              rep.isSubCatEditable
            ) {
              const reqLocalData = {
                ...localState[catInd].sub_category[subCatIndex].sub_category[
                  repIndex
                ],
                isSubCatEditable: false,
              };
              infoState = updateSubState(
                infoState,
                catInd,
                subCatIndex,
                reqLocalData,
                repIndex
              );
              localState = updateSubState(
                localState,
                catInd,
                subCatIndex,
                reqLocalData,
                repIndex
              );
            }
          });
        }
      });
    });
    return { infoState, localState };
  };

  const getProvisioningDetails = () => {
    const provisioning_meta = [];
    register_app_template.data?.track_status_template?.fields
      .slice(2)
      .forEach((item) => {
        if (item.project_type === "both") {
          provisioning_meta.push({
            resource_name: item.label,
            provisoning_status:
              item.key === "step_1" ? "Completed" : "To be picked up",
          });
        }
        if (
          appFields.app_details.project_type ===
          "Custom GenAI Infra Provisioning"
        ) {
          if (item.project_type === "") {
            provisioning_meta.push({
              resource_name: item.label,
              provisoning_status:
                item.key === "step_1" ? "Completed" : "To be picked up",
            });
          }
        }
      });
    const provisioning_details = {
      updated_on: moment().valueOf(),
      updated_by: userInfo.preferred_username,
      provisioning_status: "pending",
      provisioning_meta,
      api_framework: configDetails.api_framework || "",
      backend_stack: configDetails.backend_stack || "",
      provision_database: configDetails.provision_database || "",
      // ERROR: excluding firestore collection if provision database is equal to None
      db_name: `cdh-${configDetails.db_name || ""}-data-reckitt`,
      // configDetails.provision_database === "None"
      //   ? `cdh-${configDetails.db_name || ""}-data-reckitt`
      //   : `cdh-${configDetails.db_name || ""}-data-reckitt`,
      deployment_env: getSubmitableData(configDetails.deployment_env),
      provisioning_access_list: configDetails.provisioning_access_list || [],
      frontend_repository_name: `GCP_${
        configDetails.fe_repo_name || ""
      }_Data_Reckitt`,
      backend_repository_name: `GCP_${
        configDetails.be_repo_name || ""
      }_Data_Reckitt`,
      requestors_comments: configDetails.requestors_comments || "",
      gcs_bucket: configDetails.gcs_bucket,
      frontend_framework: configDetails.frontend_framework,
      llm_model: configDetails.llm_model,
      llm_model_name: configDetails.llm_model_name,
      ...(appFields?.app_details.project_type ===
      "Custom GenAI Infra Provisioning"
        ? {
            azure_databricks_notebook: configDetails.azure_databricks_notebook,
            azure_cluster_name: `Azure-${
              appFields.app_details.app_handle || ""
            }-data-reckitt`,
            azure_git_repo: `Azure_test-${
              appFields.app_details.app_handle || ""
            }_Notebook-data-reckitt`,
            azure_cluster_type: configDetails.azure_cluster_type,
            azure_terminate: configDetails.azure_terminate,
            azure_autoscaling: configDetails.azure_autoscaling,
            model_specific_database: configDetails.model_specific_database,
            database_options: configDetails.database_options,
            vector_datastore: configDetails.vector_datastore,
            vector_datastore_type: configDetails.vector_datastore_type,
            text_search: configDetails.text_search,
            text_search_type: configDetails.text_search_type,
            object_store: configDetails.object_store,
            object_store_type: configDetails.object_store_type,
            integration_with_trinity: configDetails.integration_with_trinity,
            sap_checkbox: configDetails.sap_checkbox,
            simba_checkbox: configDetails.simba_checkbox,
            bard_checkbox: configDetails.bard_checkbox,
            i5_checkbox: configDetails.i5_checkbox,
            hive_checkbox: configDetails.hive_checkbox,
            nodum_checkbox: configDetails.nodum_checkbox,
            marketing_checkbox: configDetails.marketing_checkbox,
            supply_checkbox: configDetails.supply_checkbox,
            theia_checkbox: configDetails.theia_checkbox,
          }
        : { additional_approvers: configDetails.additional_approvers }),
    };
    return { provisioning_details };
  };

  const getAppRegisteringData = (draft) => {
    const defaultAccess = {
      user_id: userInfo.preferred_username,
      name: userInfo.name,
      role: "owner",
      access_granted_by: userInfo.preferred_username,
      access_granted_on: moment().valueOf(),
    };
    let access_details = {};
    if (draftId) {
      access_details = {
        ...appDetails.data?.access_details,
        users_list: [
          ...appDetails.data?.access_details?.users_list,
          ...addAccessList,
        ],
      };
    } else {
      if (appFields.app_type === "ml_app") {
        access_details = {
          users_list: [
            {
              ...defaultAccess,
              security_filters: [
                {
                  country: "All",
                  brands_list: ["All"],
                },
              ],
            },
            ...addAccessList,
          ],
          access_type,
        };
      } else {
        access_details = {
          users_list: [defaultAccess, ...addAccessList],
          access_type,
        };
      }
    }
    const approval_meta = {
      updated_on: moment().valueOf(),
      updated_by: userInfo.role !== "user" ? userInfo.preferred_username : "",
      approval_status: draft
        ? "draft"
        : (userInfo.role === "admin" || userInfo.role === "super_admin") &&
          appFields.app_type !== "ml_app"
        ? "approved"
        : "pending",
    };

    let app_meta = {
      app_name: appFields.app_name,
      app_path: "",
      app_location_type: "",
      app_type: appFields.app_type,
      app_logo_type: appFields.app_logo_type || "google_font",
      app_logo: appFields.app_logo,
      blob_name: appFields.blob_name || "",
      created_on: moment().valueOf(),
      created_by: userInfo.preferred_username,
      activation_status: "active",
      total_views: 0,
    };
    let app_details = {
      ...appFields.app_details,
    };
    return { app_meta, approval_meta, access_details, app_details };
  };

  const getBiRegisteringData = (draft = "", categories = categoryInfo) => {
    let { app_meta, approval_meta, access_details, app_details } =
      getAppRegisteringData(draft);
    let defined_categories;
    if (
      appTemplateState.template_no === 1 ||
      appTemplateState.template_no === "Template_1"
    ) {
      defined_categories = getCategories(categories);
    } else {
      defined_categories = categories;
    }
    app_details = {
      ...app_details,
      defined_categories: defined_categories,
      chosen_template: getTemplateStringInNumber(template_no),
      temp_img_data,
      workspace_id: selectedWorkspace,
    };
    return { app_meta, approval_meta, access_details, app_details };
  };

  const getMLRegisteringData = (draft = "") => {
    let { app_meta, approval_meta, access_details, app_details } =
      getAppRegisteringData(draft);
    app_meta = {
      ...app_meta,
      app_url: `https://${appFields.app_url || ""}-prd${
        config.hardCoded.appUrl
      }`,
    };
    const { provisioning_details } = getProvisioningDetails();
    return {
      app_meta,
      app_details,
      approval_meta,
      access_details,
      provisioning_details,
    };
  };

  const getStandaloneRegisteringData = (draft = "") => {
    let { app_meta, approval_meta, access_details, app_details } =
      getAppRegisteringData(draft);
    app_meta = {
      ...app_meta,
      app_url: appFields.app_url || "",
      doc_url: appFields.doc_url || "",
    };
    return { app_meta, approval_meta, access_details, app_details };
  };

  const handleDraftSubmit = async (pageName, updatedData = categoryInfo) => {
    eventTracker("Save as draft", pageName);
    dispatch(updateDraftstatus("loading"));
    let data = {};
    if (appFields.app_type === "bi_app") {
      let { app_meta, approval_meta, access_details, app_details } =
        getAppRegisteringData("draft");
      app_details = {
        ...app_details,
        defined_categories: updatedData,
        chosen_template: getTemplateStringInNumber(template_no),
        temp_img_data,
        workspace_id: selectedWorkspace,
      };
      data = { app_meta, approval_meta, access_details, app_details };
    } else if (appFields.app_type === "ml_app") {
      data = getMLRegisteringData("draft");
    } else if (appFields.app_type === "standalone_app") {
      data = getStandaloneRegisteringData("draft");
    } else return;
    if (
      appFields.app_type === "ml_app" &&
      appFields.app_details?.app_handle &&
      !(
        unique_app_handle.status === "success" ||
        (draftId &&
          appFields.app_details?.app_handle ===
            appDetails.data.app_details?.app_handle)
      )
    ) {
      data = {
        ...data,
        app_details: { ...data.app_details, app_handle: "" },
      };
    }
    if (draftId) {
      data = { ...data, app_id: draft_id };
    }
    const { isSuccess, errorMSG, statusMsg } = await update_draft_apps(
      data,
      draftId ? "PUT" : "POST"
    );
    if (isSuccess) {
      setDraftApp(true);
      setAlertMsg(statusMsg);
      setOpen(true);
      dispatch(updateDraftstatus("success"));

      dispatch(update_statusData({ dirty: false }));
    } else {
      toast.error(
        errorMSG || "Oops ! unable to save changes, please try later!"
      );
    }
  };

  const handleSubmit = async (categories = categoryInfo) => {
    dispatch(update_statusData({ waiting: true }));
    let data = {};
    if (app_id || isAdminRegistering) {
      data = {
        access_details: {
          ...appDetails.data.access_details,
          users_list: [
            ...appDetails.data.access_details?.users_list,
            ...addAccessList,
          ],
        },
        approval_meta: appDetails.data.approval_meta,
        app_id: app_id || appDetails.data.app_id,
      };
      let app_meta = {
        ...appDetails.data.app_meta,
        app_name: appFields.app_name,
        app_logo: appFields.app_logo,
        app_logo_type: appFields.app_logo_type || "google_font",
        blob_name: appFields.blob_name || "",
        total_views: appFields.total_views,
      };
      let app_details = {
        ...appDetails.data.appFields,
        ...appFields.app_details,
      };
      if (appFields.app_type === "bi_app") {
        let defined_categories;
        if (
          appTemplateState.template_no === 1 ||
          appTemplateState.template_no === "Template_1"
        ) {
          defined_categories = getCategories(categories);
        } else {
          defined_categories = categories;
        }
        app_details = {
          ...app_details,
          chosen_template: getTemplateStringInNumber(template_no),
          defined_categories: defined_categories,
          temp_img_data,
        };
      } else if (appFields.app_type === "ml_app") {
        app_meta = {
          ...app_meta,
          app_url: `https://${appFields.app_url}-prd${config.hardCoded.appUrl}`,
        };
        data = {
          ...data,
          provisioning_details: {
            ...appDetails.data.provisioning_details,
            provisioning_access_list:
              configDetails.provisioning_access_list || [],
            deployment_env: getSubmitableData(configDetails.deployment_env),
          },
        };
      } else if (appFields.app_type === "standalone_app") {
        app_meta = {
          ...app_meta,
          doc_url: appFields.doc_url,
          app_url: appFields.app_url,
        };
      }
      data = { ...data, app_meta, app_details };
    } else {
      if (appFields.app_type === "bi_app") {
        data = getBiRegisteringData("", categories);
      } else if (appFields.app_type === "ml_app") {
        data = getMLRegisteringData();
      } else if (appFields.app_type === "standalone_app") {
        data = getStandaloneRegisteringData();
      } else return;
      if (draftId) {
        data = { ...data, app_id: draft_id };
      }
    }
    try {
      const res = await fetchAPIRegister(data, app_id || isAdminRegistering);
      const result = await res.json();
      if (
        result.status?.status === "datareckitt-200" ||
        result.status?.statusCode === "datareckitt-200"
      ) {
        // setAlertMsg(result.status?.statusMessage);
        // setOpen(true);
        if (result.status?.app_id !== undefined) {
          dispatch(update_appData({ appInfoID: result.status?.app_id }));
          setOpen(true);
          // setSelectedTemplate(null);
          setAlertMsg(result.status?.statusMessage);
        } else {
          setAlertMsg(
            "Congratulations! Your application is successfully Updated!"
          );
          setOpen(true);
        }

        if (
          (userInfo.role === "admin" ||
            userInfo.role === "super_admin" ||
            appFields.app_type === "ml_app") &&
          !app_id & !draftId
        ) {
          const reqInfo = {
            type: "app_id",
            app_id: result.status.app_id || appDetails.data.app_id,
            approval_status:
              appFields.app_type === "ml_app" ? "pending" : "approved",
          };
          if (isAdminRegistering) {
            dispatch(updateAppDetails(reqInfo, "admin"));
          } else {
            dispatch(getAppDetails(reqInfo, "admin"));
          }
        }
        // used to update new tags and datasets in backend
        handleSaveTagsDatasets();
        // used to send mail
        if (appFields.app_type === "ml_app") {
          sendmailToOpsRequest({
            app_id: result.status.app_id || appDetails.data.app_id,
            approval_status: "pending",
            operation: appInfo || isAdminRegistering ? "update" : "register",
          });
        } else {
          if (userInfo.role === "user") {
            if (app_id) {
              sendmailRequest({
                app_id,
                operation: "updation_request",
              });
            } else {
              sendmailRequest({
                app_id: result.status.app_id,
                operation: "register_request",
              });
            }
          }
        }
      } else {
        setAlertMsg(result.status?.statusMessage);
        setOpenSubmitError(true);
      }
      dispatch(update_statusData({ waiting: false }));
      dispatch(update_statusData({ dirty: false }));
    } catch (error) {
      console.log("error form api", error);
      toast.error("Oops Something went wrong. Please try later");
      dispatch(update_statusData({ waiting: false }));
      dispatch(update_statusData({ dirty: false }));
    }
  };

  const handleupdateRoute = (val) => {
    if (appDetails.status !== "success") return;
    if (val === 2) {
      history.push(
        "/register" + appDetails.data.app_meta.app_path + "/details"
      );
    } else if (appFields?.app_type === "ml_app") {
      if (val === 6) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/usage-report"
        );
      } else if (val === 5) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/access-management"
        );
      } else if (val === 4) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/track-status"
        );
      } else if (val === 3) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/configurations"
        );
      }
    } else if (appFields?.app_type === "standalone_app") {
      if (val === 5) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/usage-report"
        );
      } else if (val === 4) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/access-management"
        );
      } else if (val === 3) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/configurations"
        );
      }
    } else if (appFields?.app_type === "bi_app") {
      if (val === 5) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/usage-report"
        );
      } else if (val === 4) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/access-management"
        );
      } else if (val === 3) {
        history.push(
          "/register" + appDetails.data.app_meta.app_path + "/layout"
        );
      }
    }
  };

  // run first time whenever user visit in register page whether updating phase or just registering(send api calls)
  useEffect(() => {
    // console.log("CALLED-1");
    UpdatePageView("register");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "Register_flow",
      app_type: app_id || draft_id ? "Update" : "New app",
      app_id: app_id || draft_id,
    });
    if (appInfo || draftId) {
      dispatch(
        getAppDetails({
          type: "app_id",
          app_id: app_id || draft_id,
          approval_status,
        })
      );
    }
    if (register_app_template.status !== "success") {
      dispatch(getRegisterAppTemplate());
    }
    return () => {
      dispatch(cleanAppDetails());
      dispatch(cleanTempState());
      dispatch(clean_register_states());
    };
  }, [appInfo, dispatch, draftId]);

  // run when user visit register page => it set default app_type while registering but not updating
  useEffect(() => {
    // console.log("CALLED-2");
    if (
      register_app_template.status === "success" &&
      !(appInfo || isAdminRegistering || draftId)
    ) {
      const { options } =
        register_app_template.data?.register_app_template?.fields[0];
      const selectedBtn = options?.find((option) => option.selected === true);

      dispatch(
        update_appData({
          appFields: { ...appFields, app_type: selectedBtn.key },
        })
      );
    }
  }, [register_app_template.status]);

  // run when admin is registering and allows admin to complete other steps once the app is registered without changing the route(valid only in /register route).
  useEffect(() => {
    // console.log("CALLED-3");
    const rslt =
      !(appInfo || draftId) &&
      appDetails.message === "admin" &&
      (userInfo.role !== "user" || appFields.app_type === "ml_app");

    dispatch(update_statusData({ isAdminRegistering: rslt }));
  }, [appDetails.message, appFields.app_type, appInfo, draftId, userInfo.role]);

  // run everytime whenever app_type changes and set values in options and default values in details page
  useEffect(() => {
    // console.log("CALLED-4");
    if (register_app_template.status !== "success" || !appFields.app_type)
      return;
    const templates = getDetailsTemplates("details");
    if (!templates) return;
    if (appInfo || draftId) {
      let optionsList = {};
      templates.fields.forEach((item) => {
        if (config.specialFields.includes(item.key)) {
          if (item.key === "geography") {
            let selectedRegions = {};
            appFields.app_details[item.key].forEach((reg) => {
              selectedRegions = {
                ...selectedRegions,
                [reg.region]: reg.countries,
              };
            });
            const selectedRegOptions = [];
            const unSelectedRegOptions = [];
            const regionsList = Object.keys(selectedRegions);
            item.options.forEach((opt) => {
              if (regionsList.includes(opt.region)) {
                const unSelectedCountries = opt.countries.filter(
                  (country) => !selectedRegions[opt.region].includes(country)
                );
                selectedRegOptions.push({
                  region: opt.region,
                  countries: [
                    ...selectedRegions[opt.region],
                    ...unSelectedCountries.sort(),
                  ],
                });
              } else {
                unSelectedRegOptions.push(opt);
              }
            });
            optionsList = {
              ...optionsList,
              [item.key]: [...selectedRegOptions, ...unSelectedRegOptions],
            };
          } else {
            let filteredData = item.options.filter(
              (val) => !appFields.app_details[item.key]?.includes(val)
            );
            // sorting the existing selected options & rest option
            optionsList = {
              ...optionsList,
              [item.key]: [
                ...shortedData(
                  Array.isArray(appFields.app_details[item.key])
                    ? appFields.app_details[item.key]
                    : []
                ),
                ...shortedData(filteredData),
              ],
            };
          }
        }
      });

      dispatch(update_appData({ dataList: optionsList }));
    } else {
      let optionsList = {};
      let defaultValue = {};
      let app_logo = "";
      let app_logo_type = "";
      templates.fields.forEach((item) => {
        if (config.specialFields.includes(item.key)) {
          //checking if value exist else
          if (item.key === "geography") {
            optionsList = {
              ...optionsList,
              [item.key]: item.options,
            };
          } else if (item.value?.length) {
            const value = Array.isArray(item.value) ? item.value : [];
            const filteredOptions = item.options.filter(
              (opt) => !value.includes(opt)
            );
            const reqList = [
              ...shortedData(value),
              ...shortedData(filteredOptions),
            ];
            optionsList = {
              ...optionsList,
              [item.key]: reqList,
            };
          } else {
            optionsList = {
              ...optionsList,
              [item.key]: shortedData(item.options),
            };
          }
        }
        if (item.key === "app_logo") {
          app_logo = item.value;
          app_logo_type = "google_font";
          //revert this as well
        } else if (item.key !== "app_name") {
          if (item.key === "geography") {
            defaultValue = {
              ...defaultValue,
              [item.key]: [
                {
                  region: "All",
                  countries: ["All"],
                },
              ],
            };
          } else {
            defaultValue = {
              ...defaultValue,
              [item.key]: item.value,
            };
          }
        }
      });

      dispatch(update_appData({ dataList: optionsList }));

      dispatch(
        update_appData({
          appFields: {
            ...appFields,
            app_logo,
            app_logo_type,
            app_details: { ...appFields.app_details, ...defaultValue },
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register_app_template.status, appInfo, appFields.app_type]);

  // ----------------------
  // No:5
  // run when user has already registered his app and now he wants to see or update its registered app
  useEffect(() => {
    // console.log("CALLED-5");
    if (oldThemeValue.current !== theme) {
      oldThemeValue.current = theme;
      return;
    }
    if (
      (app_id || draftId || isAdminRegistering) &&
      appDetails.status === "success" &&
      appDetails.data
    ) {
      if (
        // checking if owner or app admin has open the app.
        !(
          (ownerList(appDetails.data, "admin") || []).includes(
            userInfo.preferred_username
          ) ||
          userInfo.role === "super_admin" ||
          (userInfo.role === "admin" &&
            appDetails.data.approval_meta.approval_status === "pending")
        )
      ) {
        history.push("/explore");
        dispatch(cleanAppDetails());
        dispatch(cleanTempState());
      }
      let defaultValues = {};
      defaultValues = {
        ...appDetails.data.app_meta,
        app_details: appDetails.data.app_details,
      };
      if (
        draftId &&
        appDetails.data.app_meta?.app_type === "ml_app" &&
        !appDetails.data.app_details.app_handle
      ) {
        const app_handle = defaultValues.app_name
          ?.trim()
          ?.toLowerCase()
          ?.replaceAll(" ", "_");
        defaultValues = {
          ...defaultValues,
          app_details: { ...defaultValues.app_details, app_handle },
        };
      }
      // trimming the rbone.net from the url in app url;
      if (appDetails.data.app_meta.app_type === "ml_app") {
        let newUrl = appDetails.data.app_meta.app_url;
        defaultValues = {
          ...defaultValues,
          app_url: newUrl.substring(8, newUrl.length - 14),
          approval_meta: appDetails.data.approval_meta,
        };
        setDefaultConfigData(appDetails.data.provisioning_details);

        dispatch(
          update_MLappDetails({
            trackStatusState:
              appDetails.data.provisioning_details.provisioning_meta,
          })
        );
      }

      dispatch(
        update_appData({
          appFields: defaultValues,
        })
      );

      dispatch(
        update_BIappDetails({
          categoryInfo: appDetails.data.app_details.defined_categories || [],
        })
      );
      dispatch(
        update_BIappDetails({
          categoryInfoLocal:
            appDetails.data.app_details.defined_categories || [],
        })
      );

      dispatch(
        updateReportTemplate({
          template_no: appDetails.data.app_details.chosen_template,
          templateData: appDetails.data.app_details.defined_categories || [],
          temp_img_data: appDetails.data.app_details.temp_img_data,
        })
      );

      dispatch(
        update_accessManagement({
          accessList: appDetails.data.access_details.users_list,
          accessListClone: appDetails.data.access_details.users_list,
        })
      );
      dispatch(
        update_appData({
          access_type: appDetails.data.access_details.access_type,
        })
      );
    } else if (
      (app_id || draftId) &&
      appDetails &&
      appDetails.status === "success" &&
      !appDetails.data
    ) {
      dispatch(cleanAppDetails());
      dispatch(cleanTempState());
      history.push("/register");
    } else if ((app_id || draftId) && appDetails.status === "error") {
      dispatch(cleanAppDetails());
      dispatch(cleanTempState());
      history.push("/explore");
    }
  }, [
    register_app_template,
    appDetails,
    app_id,
    history,
    userInfo,
    // dispatch,
    userInfo.role,
    isAdminRegistering,
    draftId,
  ]);

  // No:6
  // tracking the change if any and allowing user to update the app.
  useEffect(() => {
    // console.log("CALLED-6");
    if (
      (!(appInfo || draftId) && !appDetails.message) ||
      !Object.keys(appDetails.data).length
    )
      return;
    const { app_details, app_meta } = appDetails.data;
    let rslt = false;
    let detailsRslt = false;
    Object.keys(isChanged["app_details"]).some((item) => {
      if (
        config.specialFields.includes(item) &&
        item !== "geography" &&
        !equal(
          isChanged?.app_details[item].sort((a, b) =>
            a.localeCompare(b, undefined, {
              sensitivity: "base",
            })
          ),
          app_details[item].sort((a, b) =>
            a.localeCompare(b, undefined, {
              sensitivity: "base",
            })
          )
        )
      ) {
        return (detailsRslt = true);
      } else if (
        item === "geography" &&
        !equal(
          isChanged?.app_details[item].sort((a, b) => a.region - b.region),
          app_details[item].sort((a, b) => a.region - b.region)
        )
      ) {
        return (detailsRslt = true);
      } else if (
        !config.specialFields.includes(item) &&
        isChanged["app_details"][item] !== app_details[item]
      ) {
        return (detailsRslt = true);
      } else {
        return false;
      }
    });
    Object.keys(isChanged["app_meta"]).forEach((item) => {
      if (
        // (item !== "app_url" || (item === "app_url" && draftId)) &&
        isChanged["app_meta"][item] !== app_meta[item] &&
        item !== "app_url" &&
        item !== "doc_url"
      ) {
        detailsRslt = true;
      }
    });
    // setIsDetailsSubmitable(detailsRslt);
    dispatch(update_statusData({ isDetailsSubmitable: detailsRslt }));

    // next 3 conditions are used to track the changes in configuration & category page
    if (
      appFields.app_type === "bi_app" &&
      (categoryInfoLocal.length || appTemplateState?.templateData?.length)
    ) {
      let defined_categories = [];
      if (draftId) {
        if (appTemplateState?.template_no > 5) {
          defined_categories = appTemplateState.templateData;
        } else {
          defined_categories = categoryInfoLocal;
        }
      } else {
        // TODO: fix this part
        if (
          appTemplateState?.template_no === 1 ||
          appTemplateState.template_no === "Template_1"
        ) {
          defined_categories = getCategories(categoryInfoLocal);
        } else if (appTemplateState?.template_no > 5) {
          defined_categories = appTemplateState.templateData;
        } else {
          defined_categories = categoryInfoLocal;
        }
      }

      if (
        !equal(defined_categories, app_details?.defined_categories) ||
        (Object.keys(templateData).length !== 0 &&
          !isEqualObject(defined_categories, templateData))
      ) {
        rslt = true;
      }
    }

    if (appFields.app_type === "ml_app") {
      Object.keys(isChanged["provisioning_details"]).forEach((item) => {
        if (
          item === "deployment_env" &&
          isChanged.provisioning_details?.deployment_env?.length
        ) {
          rslt = true;
        } else if (
          item !== "deployment_env" &&
          !equal(
            isChanged["provisioning_details"][item],
            appDetails.data?.provisioning_details[item]
          )
        ) {
          rslt = true;
        }
      });
    }

    if (appFields.app_type === "standalone_app") {
      const { app_url, doc_url } = isChanged.app_meta;
      if (
        (app_url && app_url !== appDetails.data.app_meta.app_url) ||
        (doc_url && doc_url !== appDetails.data.app_meta.doc_url)
      ) {
        rslt = true;
      }
    }
    dispatch(update_statusData({ isChangesSubmitable: rslt }));
    dispatch(update_statusData({ dirty: detailsRslt || rslt }));
  }, [
    isChanged,
    appDetails,
    appInfo,
    categoryInfoLocal,
    appFields.app_type,
    isAdminRegistering,
    draftId,
  ]);
  // ----------------------

  // used to validate standalone app url everytime when app_url and app_type changes
  useEffect(() => {
    // console.log("CALLED-7");
    let isCancelled = false;
    const handleValidation = async (value) => {
      if (
        !value ||
        (value && !value.trim()) ||
        ((draftId || appInfo || isAdminRegistering) &&
          appFields.app_url === appDetails.data?.app_meta?.app_url)
      )
        return;
      await timeout(600);
      if (!isCancelled) {
        dispatch(
          validateAppUrl({
            app_url: value.trim(),
          })
        );
      }
    };
    if (appFields.app_type === "standalone_app") {
      handleValidation(appFields.app_url);
    }
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appFields.app_url, appFields.app_type]);

  // used to validate app_name
  useEffect(() => {
    // console.log("CALLED-8");
    let isCancelled = false;
    const handleValidation = async (value) => {
      // console.log(
      //   "MY-DATA-val-detail-ID-Info-Admin",
      //   value,
      //   appDetails?.data.app_meta?.app_name,
      //   draftId,
      //   appInfo,
      //   isAdminRegistering
      // );
      // undefined: value, appDetails, draftId
      // appInfo: bedcf11f-49c9-4-active_pending
      // isAdminRegistering: false

      // undefind: draftId

      if (
        !value ||
        (value && !value.trim()) ||
        (appDetails.data.app_meta?.app_name?.toLowerCase() ===
          value.toLowerCase() &&
          (draftId || appInfo || isAdminRegistering))
      ) {
        // setIsAppUnique(false);
        dispatch(update_statusData({ isAppUnique: false }));
        setValidationMsg("");
        return;
      }

      if (appInfo && appDetails?.data.app_meta?.app_name === undefined) {
        return;
      }
      await timeout(1000);
      if (!isCancelled) {
        // setWaiting(true);
        dispatch(update_statusData({ waiting: true }));
        try {
          const res = await fetchAPI(value.trim());
          const result = await res.json();
          if (result.status.status === "datareckitt-200") {
            // setIsAppUnique(true);
            dispatch(update_statusData({ isAppUnique: true }));
            setValidationMsg(result.status.statusMessage);
            // setWaiting(false);
            dispatch(update_statusData({ waiting: false }));
          } else {
            // setIsAppUnique(false);
            dispatch(update_statusData({ isAppUnique: false }));
            setValidationMsg(result.status.statusMessage);
            // setWaiting(false);
            dispatch(update_statusData({ waiting: false }));
          }
        } catch (error) {
          console.log("error from api", error);
          // setWaiting(false);
          dispatch(update_statusData({ waiting: false }));
          toast.error("Oops Something went wrong. Please try later");
        }
      }
    };
    handleValidation(appFields.app_name);
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appFields.app_name, appInfo]);

  // run for app handle every time when user changes app_type or app handle changes
  useEffect(() => {
    // console.log("CALLED-9");
    let isCancelled = false;
    const validateAppHandle = async () => {
      // checking if app is already registered or there is value in app_handle
      if (!appFields.app_details.app_handle || isAdminRegistering || appInfo)
        return;
      else if (
        draft_id &&
        appFields.app_details?.app_handle ===
          appDetails.data?.app_details?.app_handle
      ) {
        dispatch(update_app_handle());
        return;
      }
      await timeout(600);
      if (!isCancelled) {
        dispatch(
          get_unique_app_handle({
            app_handle: appFields.app_details?.app_handle || "",
          })
        );
      }
    };
    if (appFields.app_type === "ml_app") {
      validateAppHandle();
    }
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appFields.app_details.app_handle, appFields.app_type]);

  useEffect(() => {
    // console.log("CALLED-10");
    if (appDetails.status === "success") {
      if (!tab && (appInfo || isAdminRegistering)) {
        if (!window.location.pathname.endsWith("/register")) {
          history.push(appDetails.data.app_meta.app_path + "/details");
        }
        // setActiveStepper(2);
        dispatch(update_activeStepper(2));
      } else if (tab && (appInfo || isAdminRegistering)) {
        if (tab === "details") {
          // setActiveStepper(2);
          dispatch(update_activeStepper(2));
        } else if (appDetails.data.app_meta.app_type === "ml_app") {
          if (tab === "access-management") {
            // setActiveStepper(5);
            dispatch(update_activeStepper(5));
          } else if (tab === "configurations") {
            // setActiveStepper(3);
            dispatch(update_activeStepper(3));
          } else if (tab === "track-status") {
            // setActiveStepper(4);
            dispatch(update_activeStepper(4));
          } else if (tab === "usage-report") {
            // setActiveStepper(6);
            dispatch(update_activeStepper(6));
          }
        } else if (appDetails.data.app_meta.app_type === "standalone_app") {
          if (tab === "access-management") {
            // setActiveStepper(4);
            dispatch(update_activeStepper(4));
          } else if (tab === "configurations") {
            // setActiveStepper(3);
            dispatch(update_activeStepper(3));
          } else if (tab === "usage-report") {
            // setActiveStepper(5);
            dispatch(update_activeStepper(5));
          }
        } else if (appDetails.data.app_meta.app_type === "bi_app") {
          if (tab === "access-management") {
            // setActiveStepper(4);
            dispatch(update_activeStepper(4));
          } else if (tab === "layout") {
            // setActiveStepper(3);
            dispatch(update_activeStepper(3));
          } else if (tab === "usage-report") {
            // setActiveStepper(5);
            dispatch(update_activeStepper(5));
          }
        }
      } else if (appDetails?.data?.app_id !== "") {
        // setActiveStepper(2);
        dispatch(update_activeStepper(2));
      }
    } else if (draftId) {
      // setActiveStepper(2);
      dispatch(update_activeStepper(2));
    } else {
      // setActiveStepper(1);
      dispatch(update_activeStepper(1));
    }
  }, [appDetails.status]);

  useEffect(() => {
    dispatch(
      update_appData({ visited: { ...visited, configurations: false } })
    );
  }, [appFields.app_details.project_type]);

  return (
    <LayoutTopSideBottom
      match={match}
      selected="/myapps"
      current_tab="/Register"
      buttonStatus={!showSubCatPage && activeStepper === 3}
      setSelectedTemplate={setSelectedTemplate}
      setOpen={setOpen}
      setAlertMsg={setAlertMsg}
      setDraftApp={setDraftApp}
    >
      <LeavePageBlocker
        when={dirty}
        navigate={(path) => history.push(path)}
        navigateWithoutSaveTrack={navigateWithoutSaveTrack}
        theme={theme}
      />
      <div className={theme || "light"}>
        <img src={semiCircle} alt="" className="semi-circle" />
        <div className={`container ${theme}-container`}>
          <RegisterAlert
            open={open}
            message={alertMsg}
            handleClose={handleCloseAlert}
            draftId={draftId || draftApp}
          />
          <NotificationModal
            open={openSubmitError}
            handleClose={handleCloseSubmitError}
            message={alertMsg}
          />
          {appInfo || isAdminRegistering
            ? // !(
              //     activeStepper === 3 &&
              //     appFields?.app_type === "bi_app" &&
              //     editable_layout &&
              //     template_val !== 1
              //   ) &&
              !isStepperHidden && (
                <>
                  <StatusBar appFields={appFields} theme={theme} />
                  <TabComponent
                    app_type={appFields.app_type}
                    status={appDetails.data.approval_meta?.approval_status}
                    appLoading={appDetails.status}
                    app_id={appDetails.data.app_id}
                    isAdminRegistering={isAdminRegistering}
                    handleupdateRoute={handleupdateRoute}
                  />
                </>
              )
            : // selectedTemplate === null ||
              // template_no !== 1 &&

              !(
                activeStepper === 3 &&
                appFields?.app_type === "bi_app" &&
                register_app_template.status === "success" &&
                template_no === 1
              ) &&
              !isStepperHidden && (
                // draftId &&
                // !(activeStepper == 3 && appFields?.app_type == "bi_app") &&
                <div style={{ zIndex: 10 }}>
                  <Container
                    disableGutters
                    maxWidth={false}
                    className={`${classes.base_container} ${classes.data_qoute}`}
                  >
                    <div className={`new-data-quote new-data-quote-${theme}`}>
                      <div className="new-data-quote-underline">
                        {config.messages.dataReckittQuote}
                      </div>
                    </div>
                  </Container>
                  <Container
                    disableGutters
                    maxWidth={false}
                    className={`${classes.base_container} ${classes.stepper_container}`}
                  >
                    <StepperComponent
                      activeStepper={activeStepper}
                      handleStepper={handleStepper}
                      app_type={appFields.app_type}
                      theme={theme}
                    />
                  </Container>
                  {/* )} */}
                </div>
              )}
          {register_app_template.status === "loading" &&
            !appInfo &&
            !draftId && <RegisterNewAppSkeletons />}

          {(register_app_template.status === "loading" ||
            appDetails.status === "loading") &&
            history.location?.tab &&
            !Object.keys(appDetails.data || {}).length &&
            appInfo && <UsageMaticsSkeletons />}

          {(register_app_template.status === "loading" ||
            appDetails.status === "loading") &&
            !history.location?.tab &&
            !Object.keys(appDetails.data || {}).length &&
            (appInfo || draftId) && <UpdateAppSkeleton draft={draftId} />}

          {appDetails.status === "loading" &&
            Boolean(Object.keys(appDetails.data || {}).length) && <Loader />}

          {/* {register_app_template.status === "success" && (
            <DetailsDrawer
              templates={getDetailsTemplates("details")}
              isFieldsEditable={isFieldsEditable}
              validationMsg={validationMsg}
              draft_app={draftId}
            />
          )} */}

          {activeStepper === 1 &&
            register_app_template.status === "success" &&
            !appInfo &&
            !draftId && (
              <AppTypeComponent
                // setActiveStepper={setActiveStepper}
                templates={register_app_template.data.register_app_template}
                appFields={appFields}
                handleChange={handleChange}
                theme={theme}
              />
            )}
          {activeStepper === 2 &&
            register_app_template.status === "success" && (
              <AppDetailsComponent
                templates={getDetailsTemplates("details")}
                // setActiveStepper={setActiveStepper}
                isFieldsEditable={isFieldsEditable}
                validationMsg={validationMsg}
                handleSaveTagsDatasets={handleSaveTagsDatasets}
                draft_app={draftId}
                handleDraftSubmit={handleDraftSubmit}
                getSubmitableData={setLocalToCatInfoData}
                template_no={template_no}
              />
            )}

          {/* BI-app Template */}
          {appFields?.app_type === "bi_app" &&
            register_app_template.status === "success" &&
            !showSubCatPage &&
            activeStepper === 3 && (
              // <ApplicationTemplate
              // setCategoryInfo={setCategoryInfo}
              // setCategoryInfoLocal={setCategoryInfoLocal}
              // categoryInfo={categoryInfo}
              // categoryInfoLocal={categoryInfoLocal}
              // appFields={appFields}
              // appInfo={appInfo}
              // isAdminRegistering={isAdminRegistering}
              // waiting={waiting}
              // activeStepper={activeStepper}
              // setActiveStepper={setActiveStepper}
              // handleStepper={handleStepper}
              // handleSubmit={handleSubmit}
              // visited={visited}
              // setVisited={setVisited}
              // isChangesSubmitable={isChangesSubmitable}
              // isDetailsSubmitable={isDetailsSubmitable}
              // handleDraftSubmit={handleDraftSubmit}
              // isFieldsEditable={isFieldsEditable}
              // register_template={getDetailsTemplates("category")}
              // setSelectedWorkspace={setSelectedWorkspace}
              // selectedWorkspace={selectedWorkspace}
              // prevWorkspace={prevWorkspace}
              // setPrevWorkspace={setPrevWorkspace}
              // />

              <LayoutSelection
                templates={getDetailsTemplates("details")}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                // setActiveStepper={setActiveStepper}
                // selectedWorkspace={selectedWorkspace}
                // setSelectedWorkspace={setSelectedWorkspace}
                // prevWorkspace={prevWorkspace}
                // setPrevWorkspace={setPrevWorkspace}
                template_no={template_no}
                isFieldsEditable={isFieldsEditable}
                register_template={getDetailsTemplates("category")}
                validationMsg={validationMsg}
                handleSaveTagsDatasets={handleSaveTagsDatasets}
                draft_app={draftId}
                handleDraftSubmit={handleDraftSubmit}
                setOpen={setOpen}
                setAlertMsg={setAlertMsg}
                setDraftApp={setDraftApp}
              />
            )}

          {/* BI-APPLICATION CATEGORY */}
          {activeStepper === 3 &&
            appFields?.app_type === "bi_app" &&
            register_app_template.status === "success" &&
            template_no === 1 && (
              <ApplicationCategory
                setSelectedTemplate={setSelectedTemplate}
                templates={getDetailsTemplates("category")}
                handleSubmit={handleSubmit}
                isFieldsEditable={isFieldsEditable}
                theme={theme}
                draft_id={draftId}
                handleDraftSubmit={handleDraftSubmit}
                getSubmitableCategories={getCategories}
                getSubmitableData={setLocalToCatInfoData}
              />
            )}

          {/* ML CONFIG APP */}
          {activeStepper === 3 &&
            appFields?.app_type === "ml_app" &&
            register_app_template.status === "success" &&
            (appFields?.app_details.project_type ===
            "Custom GenAI Infra Provisioning" ? (
              <Configuration
                // handleStepper={handleStepper}
                // activeStepper={activeStepper}
                templates={getDetailsTemplates("configuration")}
                // setActiveStepper={setActiveStepper}
                handleSubmit={handleSubmit}
                isFieldsEditable={isFieldsEditable}
                handleDraftSubmit={handleDraftSubmit}
                draft_id={draftId}
              />
            ) : (
              <ConfigurationContent
                // handleStepper={handleStepper}
                // activeStepper={activeStepper}
                templates={getDetailsTemplates("configuration_content")}
                // setActiveStepper={setActiveStepper}
                handleSubmit={handleSubmit}
                isFieldsEditable={isFieldsEditable}
                handleDraftSubmit={handleDraftSubmit}
                draft_id={draftId}
              />
            ))}

          {/* STANDALONE APP */}
          {activeStepper === 3 &&
            appFields?.app_type === "standalone_app" &&
            register_app_template.status === "success" && (
              <StandaloneConfig
                // setActiveStepper={setActiveStepper}
                templates={getDetailsTemplates("standalone_app")}
                isFieldsEditable={isFieldsEditable}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                appInfo={appInfo}
                handleDraftSubmit={handleDraftSubmit}
                draft_id={draftId}
              />
            )}

          {/* ML TRACK STATUS */}
          {activeStepper === 4 &&
            appFields?.app_type === "ml_app" &&
            register_app_template.status === "success" && (
              <TrackStatus
                templates={register_app_template.data.track_status_template}
                // setActiveStepper={setActiveStepper}
                provisioning_details={
                  appDetails.data.provisioning_details || {}
                }
                app_id={app_id || appDetails.data.app_id}
                isFieldsEditable={isFieldsEditable}
              />
            )}

          {/* ACCESS MANAGEMENT */}
          {((activeStepper === 5 && appFields?.app_type === "ml_app") ||
            (activeStepper === 4 && appFields?.app_type !== "ml_app")) &&
            register_app_template.status === "success" && (
              <AccessManagement
                // setActiveStepper={setActiveStepper}
                app_id={app_id}
                approval_status={approval_status}
                appType={appFields.app_type}
              />
            )}

          {/* USAGE REPORT */}
          {((activeStepper === 6 && appFields?.app_type === "ml_app") ||
            (activeStepper === 5 && appFields?.app_type !== "ml_app")) &&
            register_app_template.status === "success" && (
              <UsageReport theme={theme} />
            )}

          {activeStepper === 7 &&
            appFields?.app_type === "ml_app" &&
            register_app_template.status === "success" && (
              <CostComp theme={theme} />
            )}
        </div>
      </div>
    </LayoutTopSideBottom>
  );
};

const mapStateToProps = (state) => ({
  templateData: state.appTemplate.appTemplateState.templateData,
  // // categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  activeStepper: state.registerNewApp.activeStepper,
  // BIappDetails
  selectedWorkspace: state.registerNewApp.BIappDetails.selectedWorkspace,

  access_type: state.registerNewApp.appData.access_type,
  // accessManagement
  addAccessList: state.registerNewApp.accessManagement.addAccessList,
  // MLappDetails
  configDetails: state.registerNewApp.MLappDetails.configDetails,
  // BIAppDetails
  categoryInfo: state.registerNewApp.BIappDetails.categoryInfo,
  categoryInfoLocal: state.registerNewApp.BIappDetails.categoryInfoLocal,
  // statusData
  isChangesSubmitable: state.registerNewApp.statusData.isChangesSubmitable,
  isDetailsSubmitable: state.registerNewApp.statusData.isDetailsSubmitable,
  dirty: state.registerNewApp.statusData.dirty,
  waiting: state.registerNewApp.statusData.waiting,
  isAppUnique: state.registerNewApp.statusData.isAppUnique,
  isAdminRegistering: state.registerNewApp.statusData.isAdminRegistering,
  isStepperHidden: state.registerNewApp.statusData.isStepperHidden,
  // appData
  appInfoID: state.registerNewApp.appData.appInfoID,
  isChanged: state.registerNewApp.appData.isChanged,
  appFields: state.registerNewApp.appData.appFields,
  visited: state.registerNewApp.appData.visited,
  register_app_template: state.register.registerTemplate,
  appDetails: state.AppsData.appDetails,
  user: state.user.preferred_username,
  userInfo: state.user,
  usersList: state.AppsData.usersList,
  app_url_uniqueness: state.register.app_url_uniqueness,
  theme: state.user.ui_preferences?.theme,
  draft_app_status: state.register.register_draft_app,
  unique_app_handle: state.register.unique_app_handle,
  appTemplateState: state.appTemplate.appTemplateState,
  editable_layout: state.registerNewApp.statusData.editLayout,
  userRole: state.user.role,
  template_val:
    state.registerNewApp.appData.appFields.app_details?.chosen_template,
});

export default connect(mapStateToProps)(RegisterApp);
