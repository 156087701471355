import React from "react";
import { connect } from "react-redux";
import { Radio, Grid } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "../index.scss";
import { v4 } from "uuid";

const RadioBtns = (props) => {
  const {
    data,
    value,
    handleChange,
    width,
    appInfo,
    isFieldsEditable,
    isAdminRegistering,
    theme,
  } = props;
  const { label, options, key, required } = data;

  // const getRadioData = (data) => {
  //   if (key === "app_type") {
  //     return (
  //       <div>
  //         <div className="Radio-labels">{data.label}</div>
  //         <div
  //           className="desciption"
  //           style={{
  //             marginBottom: "18px",
  //           }}
  //         >
  //           {data.description}
  //         </div>
  //       </div>
  //     );
  //   }
  //   return <div className="desciption">{data}</div>;
  // };

  const getRadioData = (data) => {
    const descriptionStyle = {
      textAlign: "left",
      width: "100%",
    };
  
    const spanStyle = {
      color: "#282828",
      fontSize: "10px",
      lineHeight: "15px",
      display: "block",
    };
  
    const descriptions = {
      "GPT - 3.5 Turbo": "gpt-35-turbo, 0301",
      "GPT - 4": "gpt-4, 0613",
      "GPT - 4 Turbo": "gpt-4, turbo-2024-04-09",
      Small: "Standard_DS3_V2 14 GB Memory, 4 Cores, 0.75 DBU Databricks Runtime Version: 7.0 ML Beta (Scala 2.12, Spark 3.0.0_preview-2) Min Workers: 2, Max Workers: 8",
      Medium: "Standard_DS4_V2 28 GB Memory, 8 Cores, 1.5 DBU Databricks Runtime Version: 7.0 ML Beta (Scala 2.12, Spark 3.0.0_preview-2) Min Workers: 2, Max Workers: 8",
      Large: "Standard_DS5_V2 56 GB Memory, 16 Cores, 3 DBU Databricks Runtime Version: 7.0 ML Beta (Scala 2.12, Spark 3.0.0_preview-2) Min Workers: 2, Max Workers: 8",
    };
  
    if (key === "app_type") {
      return (
        <div>
          <div className="Radio-labels">{data.label}</div>
          <div className="desciption" style={descriptionStyle}>
            {data.description}
          </div>
        </div>
      );
    }
  
    if (descriptions[data] && (key === "azure_cluster_type" || data.startsWith("GPT"))) {
      return (
        <div className="desciption" style={descriptionStyle}>
          {data}
          <br />
          <span style={spanStyle}>{descriptions[data]}</span>
        </div>
      );
    }
  
    return <div className="desciption">{data}</div>;
  };
  

  return (
    <Grid
      item
      xs={width || 12}
      key={key}
      sx={{ pb: "12px" }}
      style={{
        // display: key === "frontend_framework" ? "flex" : "block",
        display: "flex",
      }}
    >
      <FormControl component="fieldset">
        <FormLabel
          component="legend"
          className={`heading ${theme}`}
          sx={{ pt: "8px", display: label === "" || key === "integration_with_trinity" ? "none" : "" }}
        >
          {label} <span style={{ display: required ? "" : "none" }}>*</span>
        </FormLabel>
        <RadioGroup
          value={value || null}
          onChange={(e) => handleChange(key, e.target.value)}
          row={key !== "app_type"}
          // className={
          //     key === "app_type" ? "btn-radio" : "btn-radio-default"
          // }
          className={`btn-radio-default ${theme}-btn-radio`}
        >
          {options.map((item) => (
            <div className={`${key === "azure_cluster_type"?"azure-cluster-type-radio":""}`}>
              <FormControlLabel
                key={v4()}
                value={item.key || item}
                disabled={
                  // disabled for other than these values
                  !(
                    item.key === "ml_app" ||
                    item.key === "bi_app" ||
                    item === "Python - Flask" ||
                    item === "Airflow" ||
                    item === "Databricks" ||
                    item === "H2O" ||
                    item === "Firestore" ||
                    item === "None" ||
                    item === "Yes" ||
                    item === "No" ||
                    item === "GPT - 3.5 Turbo" ||
                    item === "GPT - 4" ||
                    item === "GPT - 4 Turbo" ||
                    item === "Small" ||
                    item === "Medium" ||
                    item === "Large" ||
                    item === "Azure SQL" ||
                    item === "Azure CosmosDB"
                  ) ||
                  appInfo ||
                  isFieldsEditable ||
                  isAdminRegistering
                }
                control={
                  <Radio
                    size="small"
                    color="default"
                    sx={{ padding: "5px 9px 5px 9px" }}
                  />
                }
                label={getRadioData(item)}
              />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});

export default connect(mapStateToProps)(RadioBtns);
