import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch } from "react-redux";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import JobRole from "./JobRole";
import FunctionsInfo from "./FunctionsInfo";
import WelcomeScreen from "./welcomeScreen";
import FinalScreen from "./FinalScreen";
import LocationScreen from "./LocationScreen";
import Loader from "../../components/Loader/Loader";
import NotificationModal from "../../components/AlertMsg/NotificationModal";
import flowerDesign from "../../../assets/images/setup-images/flower-design.svg";
import semiCircle from "../../../assets/images/register-images/semiCircle.svg";

//actions
import {
  get_signup_templates,
  setCurrentTab,
  update_function_other_options,
  update_job_role_other_options,
  update_user,
} from "../../redux/actions";

import {
  affricanCountries,
  APACCountries,
  europeCountriesList,
  LATAMCountries,
  middleEastList,
  northAmericanCountries,
} from "./data";

import "./index.scss";
import { isEqualObject } from "../../utils/utils";

function fetchAPI(payload, method) {
  const url = "/api/signup";
  return fetch(url, {
    method: method || "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
}

const AccountSetup = ({ match, userInfo, theme, signup_templates }) => {
  const location = useLocation();
  const history = useHistory();
  const { tab } = useParams();
  const dispatch = useDispatch();
  const [activeScreen, setActiveScreen] = useState(1);
  const [dummyData, setDummyData] = useState([]);
  const [configData, setConfigData] = useState({});
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({});
  const [defaultList, setDefaultList] = useState({});
  const [waiting, setWaiting] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState("");
  const [othersValues, setOthersValues] = useState({
    function: "",
    job_role: "",
  });

  const handleClose = () => {
    setOpen(false);
    history.push("/profile-management");
  };
  const handleChange = (k, v) => {
    if ((configData[k] || []).includes(v)) {
      const filteredData = configData[k].filter((item) => item !== v);
      setConfigData({
        ...configData,
        [k]: filteredData,
      });
    } else {
      setConfigData({
        ...configData,
        [k]: [...(configData[k] || []), v],
      });
    }
  };

  const updateOtherJobRoleInBackend = () => {
    if (
      configData.job_role?.length &&
      !(defaultList.job_role_list || []).includes(...configData.job_role)
    ) {
      dispatch(
        update_job_role_other_options({
          job_role: configData.job_role[0],
        })
      );
    }
  };
  const updateOtherFunctionInBackend = () => {
    const otherFunctionOption = (configData.function || []).filter(
      (item) => !(defaultList.functions_Options_List || []).includes(item)
    );
    if (otherFunctionOption?.length) {
      dispatch(
        update_function_other_options({
          functions: otherFunctionOption[0],
        })
      );
    }
  };

  const handleUpdate = async (data, pageType) => {
    setWaiting(true);
    const payload = {
      user_id: userInfo.preferred_username,
      user_interests: {
        ...userInfo.user_interests,
        ...data,
      },
    };
    try {
      const res = await fetchAPI(payload, "PUT");
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
        dispatch(update_user());
        if (pageType === "job_role") {
          updateOtherJobRoleInBackend();
        } else if (pageType === "function") {
          updateOtherFunctionInBackend();
        }
        setMsg(
          response.status?.statusMessage ||
            "Your change has been sucessfully updated."
        );
        setOpen(true);
      } else {
        toast.error(
          response.status.statusMessage ||
            "Oops Unable to save changes. Please try later"
        );
      }
      setWaiting(false);
    } catch (error) {
      toast.error("Oops Unable to save changes. Please try later");
      setWaiting(false);
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      user_id: userInfo.preferred_username,
      user_interests: configData,
    };
    try {
      const res = await fetchAPI(data);
      const response = await res.json();
      if (response.status.statusCode === "datareckitt-200") {
        setActiveScreen((prev) => prev + 1);
        // updateOthersOptionsInBackend();
        updateOtherFunctionInBackend();
        updateOtherJobRoleInBackend();
        dispatch(update_user());
      } else {
        toast.error(
          response.status.statusMessage ||
            "Oops Something went wrong. Please try later"
        );
      }
      setLoading(false);
    } catch (error) {
      toast.error("Oops Something went wrong. Please try later");
      setLoading(false);
    }
  };

  // const updateMapData = useCallback((mapData) => {
  //     let reqData = [];
  //     if (mapData) {
  //         mapData.forEach((val) => {
  //             if (val === "Europe") {
  //                 reqData = [...reqData, "Europe"];
  //             } else if (val === "LATAM") {
  //                 reqData = [...reqData, ...LATAMCountries];
  //             } else if (val === "APAC") {
  //                 reqData = [...reqData, ...APACCountries];
  //             } else if (val === "North America") {
  //                 reqData = [...reqData, "Northern America"];
  //             } else if (val === "AMET") {
  //                 reqData = [...reqData, "Africa", ...middleEastList];
  //             } else {
  //                 reqData = [...reqData, val];
  //             }
  //         });
  //     }
  //     setDummyData(reqData);
  // }, []);

  // COMMENTED - but yet to ask
  // useEffect(() => {
  //   if (userInfo.user_type === "EXISTING" && !location.tab_name) {
  //     history.push("/explore");
  //   }
  // }, [history, location.tab_name, userInfo.user_type]);

  useEffect(() => {
    if (location.tab_name) {
      history.push({
        pathname:
          location.tab_name === 2
            ? "/account-setup/job-role"
            : location.tab_name === 3
            ? "/account-setup/function"
            : "/account-setup/geography",
        tab_name: location.tab_name,
      });
      setActiveScreen(location.tab_name);
    } else if (
      tab !== "job-role" &&
      tab !== "function" &&
      tab !== "geography"
    ) {
      //   history.push("/account-setup/job-role");
      history.push({
        pathname: "/account-setup/job-role",
        tab_name: 2,
      });
      setActiveScreen(2);
    } else {
      switch (tab) {
        case "job-role":
          setActiveScreen(2);
          break;
        case "function":
          setActiveScreen(3);
          break;
        case "geography":
          setActiveScreen(4);
          break;
        default:
          break;
      }
    }
  }, []);

  useEffect(() => {
    switch (activeScreen) {
      case 2:
        history.push({
          pathname: "/account-setup/job-role",
          tab_name: 2,
        });
        break;
      case 3:
        history.push({
          pathname: "/account-setup/function",
          tab_name: 3,
        });
        break;
      case 4:
        history.push({
          pathname: "/account-setup/geography",
          tab_name: 4,
        });
        break;
      default:
        break;
    }
  }, [activeScreen]);

  useEffect(() => {
    if (signup_templates.status !== "success") {
      dispatch(get_signup_templates());
    }
    dispatch(setCurrentTab("Signup screens"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // setting the options for job_role & functions and for others options
  useEffect(() => {
    if (signup_templates.status === "success") {
      const job_role =
        signup_templates.data.signup_template?.job_role?.Othersoptions || [];
      const functions =
        signup_templates.data.signup_template?.functions?.Othersoptions || [];
      const job_role_list =
        signup_templates.data.signup_template?.job_role?.options?.filter(
          (item) => item !== "Others"
        );
      const functions_Options_List =
        signup_templates.data.signup_template?.functions?.options?.filter(
          (item) => item !== "Others"
        );
      setDefaultList({ job_role_list, functions_Options_List });
      setOptions({
        job_role,
        function: functions,
      });
    }
  }, [
    signup_templates.data.signup_template?.functions?.Othersoptions,
    signup_templates.data.signup_template?.functions?.options,
    signup_templates.data.signup_template?.job_role?.Othersoptions,
    signup_templates.data.signup_template?.job_role?.options,
    signup_templates.status,
  ]);

  const handleAddDummyValues = (val) => {
    let data = [];
    if (val === "Europe") {
      data = europeCountriesList;
    } else if (val === "LATAM") {
      data = LATAMCountries;
    } else if (val === "APAC") {
      data = APACCountries;
    } else if (val === "North America") {
      data = northAmericanCountries;
    } else if (val === "AMET") {
      data = [...affricanCountries, ...middleEastList];
    } else {
      data = [val];
    }
    return data;
  };

  //setting default values while updates
  useEffect(() => {
    if (location.tab_name && Object.keys(defaultList)?.length) {
      if (activeScreen !== 5) {
        const tab = parseInt(location.tab_name);
        setActiveScreen(tab);
      }
      if (isEqualObject({}, configData)) {
        setConfigData(userInfo.user_interests || {});
      }
      // setConfigData(userInfo.user_interests || {});
      // updateMapData(userInfo.user_interests?.geography || []);
      let newData = [];
      (userInfo.user_interests?.geography || []).forEach((item) => {
        let data = handleAddDummyValues(item);
        newData = [...newData, ...data];
      });
      setDummyData(newData);
      const user_interests = userInfo.user_interests || {};
      const otherValueOfJobRole =
        user_interests.job_role?.length &&
        !(defaultList.job_role_list || []).includes(...user_interests.job_role)
          ? user_interests.job_role[0]
          : "";
      const otherValueOfFunction = user_interests.function?.find(
        (item) => !(defaultList.functions_Options_List || []).includes(item)
      );
      setOthersValues({
        job_role: otherValueOfJobRole || "",
        function: otherValueOfFunction || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultList,
    history,
    location.tab_name,
    // updateMapData,
    userInfo.user_interests,
  ]);

  return (
    <>
      <NotificationModal open={open} handleClose={handleClose} message={msg} />
      <div className={`setup-container ${theme}-setup-container`}>
        <Header match={match} />
        <div
          style={{
            position: "fixed",
            textAlign: "center",
            left: "50%",
            transform: "translateX(-50%)",
            bottom: "16px",
          }}
        >
          <img src={semiCircle} alt="" />
        </div>
        <section className="setup-section">
          <div
            className={`setup-card-container ${theme}-setup-card-container`}
            style={
              activeScreen === 1
                ? {
                    width: "503px",
                    textAlign: "center",
                  }
                : null
            }
          >
            {signup_templates.status === "loading" && (
              <Loader message="Loading" />
            )}

            {signup_templates.status === "success" && (
              <>
                <div className="setup-card-container-inner">
                  {activeScreen === 1 ? (
                    <WelcomeScreen
                      setActiveScreen={setActiveScreen}
                      template={
                        signup_templates.data.signup_template?.welcome_screen
                      }
                    />
                  ) : activeScreen === 2 ? (
                    <JobRole
                      setActiveScreen={setActiveScreen}
                      handleChange={handleChange}
                      configData={configData}
                      setConfigData={setConfigData}
                      setOthersValues={setOthersValues}
                      othersValues={othersValues}
                      theme={theme}
                      template={signup_templates.data.signup_template?.job_role}
                      options={options}
                      setOptions={setOptions}
                      job_role_list={defaultList.job_role_list}
                      handleUpdate={handleUpdate}
                      userInfo={userInfo}
                      waiting={waiting}
                      tab={location.tab_name}
                    />
                  ) : activeScreen === 3 ? (
                    <FunctionsInfo
                      setActiveScreen={setActiveScreen}
                      handleChange={handleChange}
                      configData={configData}
                      setConfigData={setConfigData}
                      handleSubmit={handleSubmit}
                      loading={loading}
                      setOthersValues={setOthersValues}
                      othersValues={othersValues}
                      theme={theme}
                      template={
                        signup_templates.data.signup_template?.functions
                      }
                      options={options}
                      setOptions={setOptions}
                      functions_Options_List={
                        defaultList.functions_Options_List
                      }
                      handleUpdate={handleUpdate}
                      userInfo={userInfo}
                      waiting={waiting}
                      tab={location.tab_name}
                    />
                  ) : activeScreen === 4 ? (
                    <LocationScreen
                      setActiveScreen={setActiveScreen}
                      dummyData={dummyData}
                      setDummyData={setDummyData}
                      handleChange={handleChange}
                      configData={configData}
                      theme={theme}
                      template={
                        signup_templates.data.signup_template?.geographies
                      }
                      handleUpdate={handleUpdate}
                      userInfo={userInfo}
                      waiting={waiting}
                      tab={location.tab_name}
                      loading={loading}
                      handleSubmit={handleSubmit}
                    />
                  ) : activeScreen === 5 ? (
                    <FinalScreen
                      setActiveScreen={setActiveScreen}
                      handleChange={handleChange}
                      configData={configData}
                      setConfigData={setConfigData}
                      tabName={location.tab_name}
                      theme={theme}
                    />
                  ) : null}
                </div>
                <div
                  style={{
                    position: "fixed",
                    textAlign: "center",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "16px",
                    zIndex: 10,
                  }}
                >
                  <img src={flowerDesign} alt="" />
                </div>
              </>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.user,
  theme: state.user.ui_preferences?.theme,
  signup_templates: state.signup.signup_templates,
});

export default connect(mapStateToProps)(AccountSetup);

// if (
//     configData.function?.length &&
//     !(defaultList.functions_Options_List || []).includes(
//         ...configData.function
//     )
// ) {
//     const reqOthersOption = configData.function.find(
//         (item) => !defaultList.functions_Options_List?.includes(item)
//     );
//     if (reqOthersOption) {
//         dispatch(
//             update_function_other_options({
//                 functions: reqOthersOption,
//             })
//         );
//     }
// }

// const OtherValueOfFunction =
//     userInfo.user_interests?.function?.find(
//         (item) =>
//             !(defaultList.functions_Options_List || []).includes(
//                 item
//             )
//     );
// const OtherValueOfJobRole =
//     userInfo.user_interests?.job_role.length &&
//     !(defaultList.job_role_list || []).includes(
//         ...userInfo.user_interests?.job_role
//     )
//         ? userInfo.user_interests?.job_role[0]
//         : "";

// const updateOthersOptionsInBackend = () => {
//     if (
//         configData.job_role?.length &&
//         !(defaultList.job_role_list || []).includes(...configData.job_role)
//     ) {
//         dispatch(
//             update_job_role_other_options({
//                 job_role: configData.job_role[0],
//             })
//         );
//     }
//     const otherFunctionOption = (configData.function || []).filter(
//         (item) => !(defaultList.functions_Options_List || []).includes(item)
//     );
//     if (otherFunctionOption?.length) {
//         dispatch(
//             update_function_other_options({
//                 functions: otherFunctionOption[0],
//             })
//         );
//     }
// };
