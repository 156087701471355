import React from "react";
import { Grid, Box } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import runError from "../../../../assets/images/register-images/run-error.svg";
import runSuccess from "../../../../assets/images/register-images/run-success.svg";
import { BootstrapInput, statusIconStyle } from "../style";
import "../index.scss";
import { connect } from "react-redux";
import { isUrlMatch } from "../../../utils/utils";
import { useCallback,useEffect } from "react";
import { handleGetErrorMessage } from "./DashboardTemplate/utils";
// import { getFieldLabel } from "./DashboardTemplate/utils";
// import { bottom } from "@popperjs/core";

const InputFiled = (props) => {
  const {
    handleChange,
    value,
    data,
    width,
    isFieldsEditable,
    waiting,
    isAppUnique,
    validationMsg,
    hasError,
    isFieldVibrating,
    isFieldRed,
    URLValid,
    setURLValid,
    localState = {},
    theme,
  } = props;
  const { label, key, required } = data;

  const handleBlur = useCallback(
    (value) => {
      const handleURLValidation = (val) => {
        if (!val.startsWith("https://")) {
          setURLValid({
            status: "Invalid",
            msg: "URL should start with (https://)",
          });
        } else if (!val.slice(8).trim().length) {
          setURLValid({
            status: "Invalid",
            msg: "Please Enter valid url",
          });
        } else if (!isUrlMatch(localState.bi_application, val)) {
          setURLValid({
            status: "Invalid",
            msg: handleGetErrorMessage(localState.bi_application),
          });
        } else {
          setURLValid({
            status: "Valid",
            msg: "",
          });
        }
      };
      if (key === "url" && value) {
        handleURLValidation(value);
      }
      return;
    },
    [key, setURLValid, localState.bi_application]
  );

  useEffect(() => {
    if (!value) return;
    handleBlur(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBlur, localState.bi_application]);
  return (
    <Grid item xs={width || 5}>
      <Box
        display="flex"
        alignItems="center"
        className={`${key === "app_name"?"appDetailsLabel-heading":"heading"} ${theme}`}
      >
        <div>
          {label}{" "}
          <span
            style={{
              display: required || key === "url" ? "" : "none",
            }}
          >
            *
          </span>
          :
        </div>
        {key === "description" && (
          <Box ml="auto" width="11%">
            <span
              style={{
                color:
                  (value && value.length > 500) || !value ? "red" : "inherit",
              }}
            >
              {value ? value.length : 0}
            </span>{" "}
            / 500
          </Box>
        )}
      </Box>
      <Box
        position={"relative"}
        sx={{ width: key === "description" || key === "project_description" ? "95%" : "100%" }}
        className={key === "description" || key === "project_description" ? "DescBox-container" : ""}
      >
        <BootstrapInput
          onChange={(e) => handleChange(key, e.target.value.trimStart())}
          value={value}
          onBlur={(e) => handleBlur(e.target.value)}
          multiline={
            key === "description" || key === "sub_category_description" || key === "project_description"
          }
          placeholder={
            key === "sub_category_name" ? "Enter text here" : `Enter ${label}`
          }
          disabled={isFieldsEditable}
          className={`${isFieldVibrating && "shake-category-field"} ${
            hasError || isFieldRed ? "error" : ""
          }`}
          fullWidth
          rows={
            key === "description" || key === "sub_category_description" || key === "project_description" ? 4 : 1
          }
          style={{
            padding: "0px",
          }}
        />
        {hasError && (
          <div className="validation_msg" style={{ color: "red" }}>
            This field is required*
          </div>
        )}
        {key === "app_name" && (
          <>
            <Box position={"absolute"} right={0} top="2px" zIndex={10}>
              {waiting && <Loader loader_size={"12px"} margin_top={"8px"}/>}
              {!waiting && isAppUnique ? (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              ) : null}
              {!waiting && !isAppUnique && validationMsg !== "" && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Already exists"
                  alt="Failed"
                />
              )}
            </Box>
            {validationMsg && !isAppUnique && !waiting && (
              <div
                className="validation_msg"
                style={{ color: isAppUnique ? "green" : "red" }}
              >
                {validationMsg}
              </div>
            )}
          </>
        )}
        {key === "url" && (
          <>
            <Box position={"absolute"} right={0} top="2px" zIndex={10}>
              {URLValid.status === "Valid" && (
                <img
                  src={runSuccess}
                  style={statusIconStyle}
                  title="Unique"
                  alt="Success"
                />
              )}
              {URLValid.status === "Invalid" && (
                <img
                  src={runError}
                  style={statusIconStyle}
                  title="Invalid URL"
                  alt="Failed"
                />
              )}
            </Box>
            {URLValid.status === "Invalid" && (
              <div className="validation_msg" style={{ color: "red" }}>
                {URLValid.msg}
              </div>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.ui_preferences?.theme,
});
export default connect(mapStateToProps)(InputFiled);
